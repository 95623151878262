import db from '../db'
import cookie from "vue-cookies"
import router from '../../router/index'

import lang from '../../utils/languageHelper'
const account_key_name = `account`
const user_id_key_name = "UserID" //cookies 的键名
const user_type_key_name = "UserType" //cookies 的键名


export default {
    namespaced: true,
    state: {
        /** 用户ID */
        UserID: 0,
        /** 用户类型 */
        UserType: 0,
        /** 真实姓名 */
        RealName: '',
        // 记录退出登录之前的页面路
        preLoginPagePath: db.get('path') || '',

    },
    actions: {
        /**
         * @description 退出
         */
        logout({ commit }, path) {
            // console.log(path)
            // 清楚令牌信息和账户信息
            commit('clear')

            let currentLanguage = lang.getTypeFromRout(path)
            let loginPath = "";
            if (currentLanguage == 0) {
                loginPath = `/CN/login/tel`
            } else {
                loginPath = `/${lang.getRouterUrlByType(currentLanguage)}/login/email`
            }
            // console.log(loginPath,233)
            router.push({
                path: loginPath,
                replace:true,
            })
        },
        /**
         * @description 登陆
         */
        login({ commit }, path) {
            this.preLoginPagePath = path;
            //console.log(path)
            // 清楚令牌信息和账户信息
            commit('clear')

            let currentLanguage = lang.getTypeFromRout(path)
            let loginPath = "";
            if (currentLanguage == 0) {
                loginPath = `/CN/login/tel`
            } else {
                //let langType = lang.getRouterUrlByType(currentLanguage)
                loginPath = `/${lang.getRouterUrlByType(currentLanguage)}/login/email`
            }
            router.push({
                path: loginPath,
                replace:true
            })
        },
    },
    mutations: {
        /**
         * 从本地存储中加载令牌
         */
        loadfromlocalstorage(state) {
            const user = db.get(account_key_name)
            if (user) {
                state.UserID = user.UserID
                state.UserType = user.UserType
                state.RealName = user.RealName
            }
        },
        /**
         * 设置登陆后跳转的页面
         */
        preLoginPageRoute(state, path) {
            //将路径存入缓存
            db.set('path',path)
            path = db.get('path')
            state.preLoginPagePath = path;
        },


        /**
         * 初始化用户信息
         */
        init(state, user) {
            let UserInfo = {
                UserID: 0,
                UserType: 0
            }
            if (!user) {
                user = db.get(account_key_name)
                UserInfo = cookie.get(cookies_key)
            }
            if (user) {
                state.UserID = user.UserID
                state.UserType = user.UserType
                state.RealName = user.RealName

                UserInfo.UserID = user.UserID
                UserInfo.UserType = user.UserType
            }
            //将登录成功后的UserID 和 UserType存入cookies
            //console.log(resdata.Data.User,753)

            cookie.set(user_id_key_name, UserInfo.UserID, "7d") //有效期7天
            cookie.set(user_type_key_name, UserInfo.UserType, "7d") //有效期7天
            db.set(account_key_name, user)   //console.log(user)
        },
        /**
        * 显示用户信息
        */
        consoleCookieInfo() {
            var user_id = cookie.get(user_id_key_name);
            if (user_id == null) {
                // console.log("user id in cookie : empty")
            } else {
                // console.log("user id in cookie :  " + user_id)
            }
        },
        /**
         * 同步cookies和vuex中的数据
         */
        syncData(state) {
            let UserInfo = {
                UserID: 0,
                UserType: 0
            }
            //判断cookie是否过了有效期
            if (cookie.get(user_id_key_name) == null) {
                UserInfo.UserID = state.UserID
                UserInfo.UserType = state.UserType

                cookie.set(user_id_key_name, state.UserID, '7d')
                cookie.set(user_type_key_name, state.UserType, '7d')
                //console.log(cookie.get(cookies_key), 99)
            } else {
                //console.log(cookie.get(cookies_key), 101)
                return
            };
        },
        /**
         * 清楚用户信息
         */
        clear(state) {
            state.UserID = 0
            state.UserType = 0
            state.RealName = ''
            db.remove(account_key_name)
            cookie.remove(user_id_key_name)
            cookie.remove(user_type_key_name)
        },
    },
}