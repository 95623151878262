module.exports = {
    ebookPage: {
        all: { ID: 787, CN: "全部", TR: "All" },
        driveType: { ID: 2366, CN: "驱动类型", TR: "Drive type" },
        motor: { ID: 846, CN: "电机", TR: "Motor" },
        diesel: { ID: 1085, CN: "柴油机", TR: "Diesel engine" },
        noProfile: { ID: 2469, CN: "暂无简介", TR: "No Profile" },
        serieslist: { ID: 1, CN: "电子样本", TR: "Product Book" },
        motorFrequency: { ID: 72, CN: "电机频率", TR: "Motor Frequency" },
        motorStandard: { ID: "", CN: "单位频率", TR: "Unit Standard" },

        displayMode: { ID: 670, CN: "显示方式", TR: "Display Mode" },
        list: { ID: 671, CN: "列表", TR: "List" },
        grid: { ID: 672, CN: "网格", TR: "Grid" },
        keyWord: { ID: 667, CN: "关键词", TR: "Key word" },
        keyWordRule: { ID: 171, CN: "请输入关键字", TR: "Please input key word" },
        view: { ID: 250, CN: "查询", TR: "View" },
        queryResults: { ID: 2470, CN: "查询结果", TR: "Query Results" },
        operation: { ID: 1208, CN: "操作", TR: "operation" },
        download: { ID: 449, CN: "下载", TR: "download" },
        type: { ID: 245, CN: "类型", TR: "type" },
        name: { ID: 447, CN: "名称", TR: "name" },
        updateTime: { ID: 772, CN: "更新时间", TR: "update time" },
        version: { ID: 773, CN: "文件版本", TR: "version" },
        lang: { ID: 450, CN: "语言", TR: "language" },
        size: { ID: 1474, CN: "文件尺寸", TR: "size" },
        desc: { ID: 448, CN: "文件描述", TR: "describe" },
        emptyFlowAndHead: { ID: 2528, CN: "流量和扬程不能为空", TR: "Flow and head cannot be empty" },
        details: { ID: 999, CN: "详情", TR: "Detail" },
        noData: { ID: 2305, CN: "暂无数据", TR: "No data available" },



        pumpList: { ID: 122, CN: "水泵列表", TR: "List" },
        spectrumList: { ID: 6, CN: "系列型谱", TR: "Spectrum" },
        modelDrawing: { ID: 182, CN: "实物图", TR: "Model Drawing" },
        seriesDescription: { ID: 144, CN: "系列说明", TR: "Series Description" },
        seriesDocuments: { ID: 693, CN: "系列文档", TR: "Series Documents" },
        video: { ID: 694, CN: "详情视频", TR: "Video" },
        model_3D: { ID: 131, CN: "三维模型", TR: "3D Model" },
        export: { ID: 276, CN: "导出", TR: "Export" },
        no: { ID: 695, CN: "序号", TR: "NO" },
        flow: { ID: 48, CN: "流量", TR: "Flow" },
        head: { ID: 49, CN: "扬程", TR: "Head" },
        catalog: { ID: 245, CN: "按类型", TR: "By Catalog" },

        selectIndustry: { ID: 2471, CN: "选择行业", TR: "Select industry" },
        allSeries: { ID: 1984, CN: "所有系列", TR: "All series" },

        productStructure: { ID: "", CN: "产品结构", TR: "Product Mix" },


    },
    selectPage: {
        selparas: { ID: 5, CN: "参数选型", TR: "Parameter Selection" },
        selResult: { ID: 2418, CN: "选型结果", TR: "Selection results" },
        index: { ID: 1437, CN: "首页", TR: "Home page" },
        selRow: { ID: 736, CN: "列选择", TR: "Column selection" },
        export: { ID: 276, CN: "导出", TR: "Export" },
        print: { ID: 192, CN: "打印", TR: "Print" },
        previous: { ID: 367, CN: "上一步", TR: "Previous step" },
        physical: { ID: 184, CN: "实物图", TR: "Model Drawing" },
        series: { ID: 253, CN: "系列", TR: "Series" },
        model: { ID: 100, CN: "型号", TR: "Model" },
        diagram: { ID: 781, CN: "曲线图", TR: "Curve graph" },
        flow: { ID: 48, CN: "流量", TR: "Flow" },
        head: { ID: 49, CN: "扬程", TR: "Head" },
        shaftPower: { ID: 109, CN: "轴功率", TR: "Shaft Power" },
        efficiency: { ID: 108, CN: "效率", TR: "Efficiency" },
        npsh: { ID: 110, CN: "汽蚀", TR: "NPSH" },
        speed: { ID: 114, CN: "转速", TR: "Speed" },
        impellerDiameter: { ID: 223, CN: "叶轮直径", TR: "Impeller Diameter" },
        details: { ID: 999, CN: "详情", TR: "Detail" },

        certifiedTraffic: { ID: "", CN: "认证流量", TR: "Certified traffic" },
        certifiedHead:{ ID: "", CN: "认证扬程", TR: "Certified head" },
        certifiedSpeed:{ ID: "", CN: "认证转速", TR: "Certified speed" },
        pointFlow_150:{ ID: "", CN: "150%点流量", TR: "150% point flow" },
        pointLift_150:{ ID: "", CN: "150%点扬程", TR: "150% point lift" },
        pointPower_150:{ ID: "", CN: "150%点功率", TR: "150% point power" },
        maxShaftPower:{ ID: "", CN: "最大轴功率", TR: "Max shaft power" },
        deadCenterLift:{ ID: "", CN: "关死点扬程", TR: "Dead center lift" },
        pumpStages:{ ID: "", CN: "泵级数", TR: "Pump stages" },
        note:{ ID: "", CN: "备注", TR: "Note" },

        gotoIndexPage: { ID: 2472, CN: "返回首页", TR: "Back to home" },
        quickSelection: { ID: 2419, CN: "快速选型", TR: "Quick selection" },
        advancedSelection: { ID: 2420, CN: "高级选型", TR: "Advanced selection" },
        workingConditions: { ID: 2421, CN: "工况条件", TR: "Operating conditions" },
        productSeries: { ID: 2422, CN: "产品系列", TR: "Product series" },
        driveType: { ID: 2423, CN: "驱动类型", TR: "Drive type" },
        setFrequency: { ID: 2424, CN: "设置频率", TR: "Set frequency" },
        moreDesignPoints: { ID: 1429, CN: "更多设计点", TR: "More design points" },
        hide: { ID: 2425, CN: "隐藏", TR: "hide" },
        secondDpFlow: { ID: 2426, CN: "第二设计点流量", TR: "Second design point flow" },
        secondDpHead: { ID: 2427, CN: "第二设计点扬程", TR: "Second design point head" },
        thirdDpFlow: { ID: 2428, CN: "第三设计点流量", TR: "Third design point flow" },
        thirdDpHead: { ID: 2429, CN: "第三设计点扬程", TR: "Third design point head" },
        firePump: { ID: 2389, CN: "消防泵", TR: "Fire pump" },
        motorPoleNum: { ID: 2430, CN: "电机级数", TR: "Motor series" },
        moreSet: { ID: 2431, CN: "更多设置", TR: "More settings" },
        put: { ID: 2432, CN: "收起", TR: "Put away" },
        motorPowerStd: { ID: 2433, CN: "电气标准", TR: "Motor power standard" },
        mediaName: { ID: 2434, CN: "介质名称", TR: "Media name" },
        cleanWater: { ID: 465, CN: "清水", TR: "Clean Water" },
        maxTemperature: { ID: 2435, CN: "最高温度", TR: "maximum temperature" },
        customMediaProp: { ID: 2436, CN: "自定义介质属性", TR: "Custom media properties" },
        maxDensity: { ID: 2437, CN: "最大密度", TR: "Maximum density" },
        mediumViscosity: { ID: 2438, CN: "介质粘度", TR: "Medium viscosity" },
        nextStep: { ID: 368, CN: "下一步", TR: "Next" },
        selectAll: { ID: 97, CN: "全选", TR: "Select All" },
        reselect: { ID: 366, CN: "取消全选", TR: "Reselect" },
        modelList: { ID: 443, CN: "型号列表", TR: "Model List" },
        byType: { ID: 2439, CN: "按类型", TR: "By type" },
        byIndustry: { ID: 2440, CN: "按行业", TR: "By industry" },
        byMedia: { ID: 2473, CN: "按介质", TR: "By media" },
        type: { ID: 247, CN: "类型", TR: "Type" },
        applicationArea: { ID: 2441, CN: "应用领域", TR: "Application area" },
        flowRule: { ID: 2143, CN: "请输入流量", TR: "Please input flow" },
        headRule: { ID: 1164, CN: "请输入扬程", TR: "Please enter the head" },
        leastOneType:{ ID: "", CN: "至少选择一种类型",TR:"Select at least one type" },
        seriesRule: { ID: 2444, CN: "至少选择一个系列", TR: "Select at least one series" },
        hangyeRule: { ID: 2474, CN: "至少选择一个行业", TR: "Select at least one industry" },
        jiezhiRule: { ID: 2475, CN: "至少选择一种介质", TR: "Select at least one media" },
        dieselEngineSpeedRule: { ID: 2443, CN: "请输入柴油机转速", TR: "Please enter the diesel engine speed" },
        dieselEngineSpeed: { ID: 2442, CN: "柴油机转速", TR: "Diesel engine speed" },
        pleaseSelect: { ID: 2042, CN: "请选择", TR: "Please select" },

        custom:{ ID: "", CN: "自定义", TR: "custom" },
        unlimited:{ ID: "", CN: "不限", TR: "unlimited" },

        toleranceStand:{ ID: "", CN: "验收容差标准", TR: "Acceptance tolerance criteria" },
        toleranceGrade:{ ID: "", CN: "验收容差等级", TR: "Acceptance tolerance level" },
        MinFlowPercentage:{ ID: "", CN: "最小流量百分比", TR: "Minimum flow percentage"},
        MaxFlowPercentage:{ ID: "", CN: "最大流量百分比", TR: "Maximum flow percentage"},
        MinHeadPercentage:{ ID: "", CN: "最小扬程百分比", TR: "Minimum head percentage"},
        MaxHeadPercentage:{ ID: "", CN: "最大扬程百分比", TR: "Maximum head percentage"},

        findErr1:{ID:"",CN:"未查找到相关型号,请修改相关选型参数",TR:"No relevant model found, please modify relevant model selection parameters"},
        findErr2:{ID:"",CN:"未查找到相关型号,将在三秒后返回上一页",TR:"No relevant model found, will return to the previous page in three seconds"}
    },
    //详情页面
    detailPage: {
        userUnit: { ID: "", CN: "用户单位",TR:"User unit" },
        standardUnit: { ID: "", CN: "标准单位",TR:"Standard unit" },

        report: { ID: 702, CN: "报告", TR: "Report" },
        share: { ID: 703, CN: "分享", TR: "Share" },
        costAnalysis: { ID: 571, CN: "成本分析", TR: "Cost Analysis" },
        medium: { ID: 1434, CN: "介质", TR: "Medium" },
        model_3D: { ID: 131, CN: "三维模型", TR: "3D Model" },
        scanQRShare: { ID: 2456, CN: "扫描二维码分享", TR: "Scan QR code sharing" },
        view: { ID: 250, CN: "查询", TR: "View" },

        close: { ID: 460, CN: "关闭", TR: "Close" },
        setUp: { ID: 470, CN: "设定", TR: "Set Up" },
        flowUnits: { ID: 2134, CN: "流量单位", TR: "Flow Units" },
        headUnit: { ID: 2135, CN: "扬程单位", TR: "Head Unit" },
        powerUnit: { ID: 2457, CN: "功率单位", TR: "Power Unit" },
        npshUnit: { ID: "", CN: "汽蚀单位",TR:"NPSH Unit" },
        d2Unit:{ ID: "", CN: "叶轮外径单位",TR:"D2 Unit"  },

        followSystem: { ID: "",CN:"跟随系统", TR: "Follow system" },

        exitFull: { ID: 2458, CN: "退出全屏", TR: "Exit Full" },
        partNo: { ID: 158, CN: "产品编号", TR: "Part No" },
        materialGroup: { ID: 1712, CN: "材料组", TR: "Material Group" },
        material: { ID: 1215, CN: "材料", TR: "Material" },

        selectionReport: { ID: 236, CN: "选型报告", TR: "Selection Report" },
        lang: { ID: 451, CN: "语言", TR: "Language" },
        fileFormat: { ID: 1532, CN: "文件格式", TR: "File Format" },
        buildFile: { ID: 2445, CN: "生成文件", TR: "Bulid File" },
        customerInfo: { ID: 795, CN: "客户信息", TR: "Customer Info" },
        orderInformation: { ID: 884, CN: "订单信息", TR: "Order Information" },
        setReportContent: { ID: 889, CN: "设置报告内容", TR: "Set report content" },
        projectInformation: { ID: 89, CN: "项目信息", TR: "Project Information" },
        productName: { ID: 881, CN: "产品名称", TR: "Product name" },
        projectCode: { ID: 1027, CN: "项目编号", TR: "Project Code" },
        projectName: { ID: 800, CN: "项目名称", TR: "Project name" },
        itemNO: { ID: 801, CN: "工位编号", TR: "Item NO" },
        customerInfo: { ID: 795, CN: "客户信息", TR: "Customer Info" },
        customerName: { ID: 883, CN: "客户名称", TR: "Customer Name" },
        customerAddress: { ID: 968, CN: "客户地址", TR: "Customer Address" },
        contactPerson: { ID: 766, CN: "联系人", TR: "Contact Person" },
        contactInformation: { ID: 35, CN: "联系方式", TR: "Contact Information" },
        email: { ID: 1801, CN: "邮箱", TR: "Email" },
        website: { ID: 1082, CN: "网址", TR: "Website" },
        country: { ID: 1083, CN: "国家", TR: "Country" },
        orderInformation: { ID: 1798, CN: "订单信息", TR: "Order Information" },
        price: { ID: 886, CN: "产品单价", TR: "Order Information" },
        pumpPeformance: { ID: 1796, CN: "订单号", TR: "Pump Peformance" },
        purchaseQuantity: { ID: 888, CN: "购买数量", TR: "Purchase Quantity" },
        orderNote: { ID: 885, CN: "订单备注", TR: "Order Note" },
        pageReportSet: { ID: 2454, CN: "页面报告设置", TR: "Page report settings" },
        isBWDispplay: { ID: 820, CN: "是否黑白显示曲线", TR: "Black and white display" },
        dataAndCurve: { ID: 817, CN: "数据表和曲线", TR: "The data sheet and performance curve" },
        curveGraph: { ID: 781, CN: "曲线图", TR: "Performance curve" },
        sizePicture: { ID: 705, CN: "尺寸图", TR: "Dimension Picture" },
        assemblyDrawing: { ID: 133, CN: "安装图", TR: "Assembly Drawing" },
        modelDrawing: { ID: 182, CN: "实物图", TR: "Model Drawing" },
        explosionDiagram: { ID: "",CN:"爆炸图", TR: "Explosion picture" },
        modelDrawinUnit: { ID: 2455, CN: "实物图(机组)", TR: "Model Drawing(system)" },
        structureDrawing: { ID: 184, CN: "结构图", TR: "Structure Drawing" },
        offerText: { ID: 814, CN: "报价文本", TR: "Offer Text" },
        companyName: { ID: 1011, CN: "公司抬头", TR: "Company Name" },
        companyWebsite: { ID: 1799, CN: "公司网址", TR: "Company Website" },
        performanceCurve: { ID: 217, CN: "性能曲线", TR: "Performance Curve" },
        assemblyDrawingPart: { ID: 2446, CN: "安装图(单泵)", TR: "Assembly Drawing " },
        assemblyDrawingSys: { ID: 2447, CN: "安装图(机组)", TR: "Assembly Drawing(system)" },
        physical: { ID: 184, CN: "实物图", TR: "Model Drawing" },
        parameter: { ID: 111, CN: "参数", TR: "Parameter" },
        productFileName: { ID: "", CN: "尺寸图", TR: "Dimension picture" },
        data: { ID: 2056, CN: "数据", TR: "Data" },
        unit: { ID: 527, CN: "单位", TR: "Unit" },
        operatingParameters: { ID: 2157, CN: "运行参数", TR: "Operating parameters" },
        parameterQuery: { ID: 2448, CN: "参数查询", TR: "Parameter Query" },
        installation: { ID: 2201, CN: "安装信息", TR: "Installation" },
        motor: { ID: 2202, CN: "电机信息", TR: "Motor" },
        documents: { ID: 693, CN: "系列文档", TR: "Documents" },
        properties: { ID: 229, CN: "属性", TR: "Properties" },
        material: { ID: 2170, CN: "材质", TR: "Material" },
        flow: { ID: 48, CN: "流量", TR: "Flow" },
        head: { ID: 49, CN: "扬程", TR: "Head" },
        shaftPower: { ID: 109, CN: "轴功率", TR: "Shaft Power" },
        efficiency: { ID: 108, CN: "效率", TR: "Eta" },

        press: { ID: "", CN: "压力", TR: "Press" },
        power: { ID: "", CN: "功率", TR: "Power" },
        impellerDia: { ID: "", CN: "叶轮外径", TR: "Impeller Dia" },
        motorPower: { ID: "", CN: "电机功率", TR: "Motor Power" },

        npsh: { ID: 110, CN: "汽蚀", TR: "NPSH" },
        speed: { ID: 114, CN: "转速", TR: "Speed" },
        coefficient: { ID: 1143, CN: "系数", TR: "Coefficient" },
        parameterSelection: { ID: 5, CN: "参数选型", TR: "Parameter Selection" },
        allowableRegionMax: { ID: 2449, CN: "允许区域(大)", TR: "Allowable Region(max)" },
        allowableRegionMin: { ID: 2450, CN: "允许区域(小)", TR: "Allowable Region(min)" },
        optimalAreaMax: { ID: 2451, CN: "最优区域(大)", TR: "Optimal Area(max)" },
        optimalAreaMin: { ID: 2452, CN: "最优区域(小)", TR: "Optimal Area(min)" },
        closeDeadCenter: { ID: 2453, CN: "关死点", TR: "Close Dead Center" },
        bestAdvantage: { ID: 2459, CN: "最优点", TR: "Best Advantage" },
        workingPoint: { ID: 202, CN: "工作点", TR: " Working Point" },
        flowPoint150: { ID: 2460, CN: "流量150%点", TR: "Flow 150% point" },
        powerPointMax: { ID: 2461, CN: "最大功率点", TR: "Maximum Power Point" },
        video: { ID: 694, CN: "系列视频", TR: "Series Video" },

        variableSpeedCurve: { ID: 2476, CN: "变速曲线", TR: "Variable speed curve" },
        displaySettings: { ID: 1614, CN: "显示设置", TR: "Display settings" },
        curveExport: { ID: 2477, CN: "曲线导出", TR: "Curve export" },

        chartColor: { ID: 2478, CN: "图表颜色", TR: "Chart color" },
        monochrome: { ID: 138, CN: "单色", TR: "monochrome" },
        multicolour: { ID: 1890, CN: "彩色", TR: "multicolour" },
        spectrumDisplay: { ID: 2479, CN: "型谱显示", TR: "spectrum display" },
        display: { ID: 2114, CN: "显示", TR: "display" },
        noDisplay: { ID: 157, CN: "不显示", TR: "no display" },
        onlyShowworkCurve: { ID: 1990, CN: "仅显示工作曲线", TR: "show only working curves" },

        speedList: { ID: 2480, CN: "速度列表", TR: "Speed list" },
        addSpeed: { ID: 2481, CN: "添加速度", TR: "add speed" },
        pleaseEnterSpeed: { ID: 1169, CN: "请输入转速", TR: "Please enter the speed" },
        tip: { ID: 285, CN: "提示", TR: "tip" },
        define: { ID: 124, CN: "确定", TR: "ok" },
        cancel: { ID: 125, CN: "取消", TR: "cancel" },
        download: { ID: 449, CN: "下载", TR: "download" },
        downloadTips: { ID: 2482, CN: "请下载桌面程序,导出性能曲线", TR: "Please download the desktop program and export the performance curve" }
    },
    //报表分析
    LCC: {
        Analysis: { ID: 569, CN: "分析", TR: "Analysis" },
        accumulationCurve: { ID: 943, CN: "年度累计曲线", TR: "Annual Accumulation Curve" },
        statisticalChart: { ID: 944, CN: "单年统计图", TR: "Statistical Chart of Year" },
        workingPointFlow: { ID: 555, CN: "工作点流量", TR: "working point flow" },
        default: { ID: 937, CN: "默认", TR: "default" },

        heating: { ID: 938, CN: "供暖", TR: "heating" },
        pressurize: { ID: 939, CN: "增压", TR: "pressurize" },
        custom: { ID: 940, CN: "自定义", TR: "custom" },
        status: { ID: 867, CN: "状态", TR: "status" },
        flowRatio: { ID: 948, CN: "流量比例", TR: "flow ratio" },
        timeScale: { ID: 949, CN: "时间比例", TR: "time scale" },
        fullLoad: { ID: 950, CN: "全负荷", TR: "full load" },
        partialLoad: { ID: 952, CN: "部分负荷", TR: "partial load" },
        nightCoolingOperation: { ID: 954, CN: "夜间降温运行", TR: "night cooling operation" },
        lowLoad: { ID: 953, CN: "低负荷", TR: "low load" },
        load: { ID: 2483, CN: "负荷", TR: "load" },

        energyCosts: { ID: 561, CN: "能耗成本", TR: "energy costs" },
        workingDays: { ID: 556, CN: "每年大致工作天数", TR: "working days" },
        workingHours: { ID: 557, CN: "每年大致工作小时", TR: "working hours" },
        powerFees: { ID: 567, CN: "电费", TR: "Electricity fees" },
        per: { ID: 565, CN: "每度", TR: "per" },
        risiPowerPrice: { ID: 2484, CN: "电费上涨", TR: "risi power price" },
        eachYear: { ID: 566, CN: "每年", TR: "each year" },
        CalcCycle: { ID: 558, CN: "计算周期", TR: "calculation cycle" },
        year: { ID: 559, CN: "年", TR: "Year" },
        interestRate: { ID: 560, CN: "利率", TR: "interest rate" },
        droop: { ID: 568, CN: "效率下降", TR: "efficiency droop" },
        co2Ratio: { ID: 936, CN: "CO2系数", TR: "CO2 ratio" },
        conversionFactor: { ID: 2529, CN: "煤炭折算系数", TR: "conversion factor" }, //!!!!!

        maintenanceCost: { ID: 536, CN: "维护维修成本", TR: "maintenance cost" },
        thousand: { ID: 2485, CN: "千元", TR: "thousand" },
        maintenance: { ID: 2486, CN: "维护和维修", TR: "maintenance" },
        runningCost: { ID: 542, CN: "运行费用", TR: "operating cost" },
        downtimeAndLoss: { ID: 540, CN: "生产停工和损耗", TR: "downtime and loss" },

        environmentalFee: { ID: 541, CN: "环境保护费", TR: "environmental fee" },
        othersFee: { ID: 792, CN: "其他年费用", TR: "other years expenses" },
        initialCost: { ID: 549, CN: "一次性初始成本", TR: "disposable initial cost" },
        orderCost: { ID: 2487, CN: "设备采购费用", TR: "order cost" },
        installDebugCharge: { ID: 551, CN: "安装和调试", TR: "install debug charge" },
        tonAndkW: { ID: 2488, CN: "吨/万千瓦", TR: "ton / 10000 kw" },

        mapOfCosts: { ID: 941, CN: "费用图", TR: "Map of costs" },
        costItem: { ID: 945, CN: "费用项", TR: "Cost item" },
        cost: { ID: 946, CN: "费用", TR: "Cost" },
        percentage: { ID: 947, CN: "百分比", TR: "Percentage" },

        initialCost: { ID: 662, CN: "初期成本", TR: "Initial cost" },
        maintenanceAndRepair: { ID: 543, CN: "维修和护理", TR: "Maintenance and repair" },
        co2: { ID: 2489, CN: "CO2", TR: "CO2" },
        coalConsumption: { ID: 2490, CN: "煤耗量", TR: "Coal consumption" },

        totalAnnualCost: { ID: 958, CN: "年总费用", TR: "Total annual cost" },
        co2TotalEmissions: { ID: 2491, CN: "co2总排放量", TR: "CO2 total emissions" },
        tenThousand: { ID: 956, CN: "万", TR: "Ten thousand" },
        tenThousandTon: { ID: 2492, CN: "万吨", TR: "Ten thousand Ton" },



    },
    //首页
    indexPage: {
        corpIntroduction: { ID: 30, CN: "公司介绍", TR: "Company Introduction" },
        basicInformation: { ID: "", CN: "基础信息",TR:"Basic information" },
        enterpriseHonors: { ID: 33, CN: "企业荣誉", TR: "Enterprise Honors" },
        enterpriseStyle: { ID: 2386, CN: "企业风貌", TR: "Enterprise style" },

        organizationStructure:{ ID: "", CN:"组织结构", TR: "organization structure" },
        corporateCulture:{ ID: "",CN:"企业文化",TR: "corporate culture" },
        introductionToPumpAnimation:{ ID: "",CN:"泵型动画介绍", TR: "Introduction to pump animation" },
        companyPortfolio:{ ID: "",CN:"公式组合", TR: "Company portfolio" },

        salesNetwork: { ID: 31, CN: "销售网络", TR: "Sales Network" },
        performanceSelection: { ID: 2387, CN: "水力性能选型", TR: "Hydraulic performance selection" },
        productSearch: { ID: 786, CN: "产品搜索", TR: "Product search" },
        contactinformation: { ID: 35, CN: "联系方式", TR: "Contact information" },
        ebook: { ID: 1, CN: "电子样本", TR: "Product Book" },
        pdfFile: { ID: 1755, CN: "纸质样本", TR: "Pdf File" },
        application: { ID: 2493, CN: "行业应用", TR: 'Application' },
        intelligentSelection: { ID: 69, CN: "智能选型", TR: "Pump Selection" },
        firePump: { ID: 2389, CN: "消防泵", TR: "Fire pump" },
        flow: { ID: 48, CN: "流量", TR: "Flow" },
        head: { ID: 49, CN: "扬程", TR: "Head" },
        type: { ID: 247, CN: "类型", TR: "Type" },
        more: { ID: 741, CN: "更多", TR: "More" },
        nextStep: { ID: 369, CN: "下一步", TR: "Next" },
        zmSearch: { ID: 2494, CN: "按字母查找", TR: "By letter" },

        alphaList: { ID: "", CN: "字母列表", TR: "Alpha List" },

        byType: { ID: 2439, CN: "按类型", TR: "By type" },
        byIndustry: { ID: 2440, CN: "按行业", TR: "By industry" },

        product:{ ID: "", CN: "产品", TR: "Product" },
        material:{ ID: "", CN: "资料", TR: "Material" },

        byProductLine:{ ID: "", CN: "按产品线", TR: "By type" },
        model_3D: { ID: 131, TR: "BIM Model" },
        about_us: { ID: 2495, CN: "关于我们", TR: "About US" },
        product_catalog: { ID: 2496, CN: "产品目录", TR: "Product Catalog" },
        product_selection: { ID: 2497, CN: "产品选型", TR: "Product Selection" },
        letter_search: { ID: 2498, CN: "字母搜索", TR: "Letter Search" },

        application_HY:{ ID: "", CN: "行业应用", TR: "Application" },

        application_area: { ID: 2441, CN: "应用领域", TR: "Application Area" },
        sample_Model: { ID: 2499, CN: "样本模型", TR: "Sample Model" },
        pleaseSelect: { ID: 2042, CN: "请选择", TR: "Please Select" },

        add: { ID: 722, CN: "公司地址", TR: "Company address:" },
        companyAddress: { ID: "", CN: "浙江省湖州市德清县雷甸镇旭日路8号", TR: "No.8, Xuri Road,  Leidian town, Deqing District, Huzhou City, Zhejiang,  China" },
        tel: { ID: 2500, CN: "联系电话", TR: "Tel:" },
        email: { ID: 2501, CN: "企业邮箱", TR: "E-mail:" },
        hotline: { ID: 2502, CN: "全国同一服务热线", TR: "National service hotline:" },
        helpManual: { ID: "", CN: "帮助手册",TR:"Help manual" },
        changePassword: { ID: "", CN: "修改密码",TR:"Change Password" },
        
        viewNow:{ ID: "", CN: "立即查看",TR:"View Now" },
        quick:{ ID: "", CN: "快速",TR:"Quick" },
        sampleBook:{ ID: "", CN: "产品样本",TR:"Sample Book" },
        simpleAndDirect:{ ID: "", CN: "清晰简捷",TR:" Simple and direct" },
        clearClassification:{ ID: "", CN: "分类明确",TR:"Clear classification" },
        quickProductSelection:{ ID: "", CN: "产品快速选取",TR:"Quick product selection" },
        productSamplePreview:{ ID: "", CN: "产品样本预览",TR:"Product sample Preview" },
        productNameAtoZ:{ ID: "", CN: "产品名称A-Z",TR:"Product name A-Z" },
        findApplications:{ ID: "", CN: "发现适合您需求的应用",TR:"Find applications that fit your needs" },

        feedBack: { ID: "", CN: "问题反馈", TR: "FeedBack" },
        feedbackContent: { ID: "", CN: "反馈内容", TR: "Feedback Content" },
        feedbackRule: { ID: "", CN: "联系人或联系方式不能为空", TR: "Contact or contact information cannot be blank!!" },

        problemType:{ ID: "", TR: "Problem type" },
        rorExample:{ ID: "", TR: "For example: software problems, curve errors" }
    },
    simpleLoginPage: {
        login: { ID: 681, CN: "登录", TR: "Login" },
        register: { ID: 381, CN: "注册", TR: "Register" },
        enterVerificCode: { ID: 2503, CN: "请输入验证码", TR: "Please enter the verification code" },
        verificCodeError: { ID: 2504, CN: "验证码错误", TR: "Verification code error" },
        loginPrompt: { ID: 2505, CN: "登录提示", TR: "Login Prompt" },
        passwordError: { ID: 400, CN: "密码错误", TR: " Password Error" },
        loginSuccessful: { ID: 2506, CN: "登录成功,正在跳转~", TR: "Login succeeded, skipping~" },
        AccountLogin: { ID: 2507, CN: "账户登录", TR: "User login" },
        CodeScanningLogin: { ID: 2390, CN: "扫码登录", TR: "Code scan login" },
        registerInfo: { ID: 2391, CN: "如果您还没有加入我们，可以点击下方的注册成为我们的一员", TR: "If you haven't joined us yet, you can click the registration below to become a member of us" },
        registerInfoTitle: { ID: 2392, CN: "新用户", TR: "New user" },
        loginInfo: { ID: 2393, CN: "如果您已经是我们中的一员可以点击下方的登录，进入到我们的平台", TR: "If you are already one of us, you can click the login below to enter our platform" },
        loginInfoTitle: { ID: 2394, CN: "我们中的一员", TR: "One of us" },
        userType: { ID: 2395, CN: "用户类型", TR: "Type" },
        loginName: { ID: 2396, CN: "账户名", TR: "Account" },
        password: { ID: 379, CN: "密码", TR: "Password" },
        userType0: { ID: 710, CN: "内部人员", TR: "Internal staff" },
        userType1: { ID: 2397, CN: "外部人员", TR: "External personnel" },
        phoneNumber: { ID: 379, CN: "手机号", TR: "Tel" },
        email: { ID: 1801, CN: "邮箱", TR: "Email" },

        forgetPassword:{ ID: "", CN: "忘记密码", TR: "Forget password" },
        sendSuccess:{ ID: "", CN: "发送成功", TR: "Send success" },
        verifyAccount:{ ID: "", CN: "账号验证", TR: "Verify account" },
        fillInInformation:{ ID: "", CN: "填写信息", TR: "Fill in information" },
        registerSuccess:{ ID: "", CN: "注册成功", TR: "Register success" },
        registerTips:{ ID: "", CN: "请填写您注册时的手机号", TR: "Please fill in your mobile phone number when registering" },

        verifyCode:{ ID: "", CN: "验证码", TR: "verifyCode" },
        send:{ ID: "", CN: "发送", TR: "send" },
        userAccount:{ ID: "", CN: "用户账号", TR: "User account" },

        oldPassword:{ ID: "", CN: "旧密码", TR: "Old password" },
        oldPsdTips:{ID: "", CN: "请输入旧密码", TR: "Please enter old password"},
        newPassword:{ ID: "", CN: "新密码", TR: "New password" },
        newPsdTips:{ID: "", CN: "请输入新密码", TR: "Please enter new password"},
        oldPwdRule:{ID: "", CN: "旧密码不能为空", TR: "Old password cannot be empty"},
        newPwdRule:{ID: "", CN: "新密码不能为空", TR: "New password cannot be empty"},
        oldAndNewPwdRule:{ID: "", CN: "新旧密码不能一致", TR: "Old and new passwords cannot be consistent"},

        enterPwdAgain:{ ID: "", CN: "请再次输入密码",TR:"Please enter the password again" },
        enterTwoPwdDisaccord:{ ID: "", CN: "两次输入密码不一致",TR:"The two passwords were not the same" },

        changeSuccess:{ID: "", CN: "修改成功", TR: "Modified successfully"},
        pwdRule:{ID: "", CN: "密码不一致", TR: "Inconsistent passwords"},
        accountSettings:{ID: "", CN: "账号设置", TR: "Account Settings"},

        confirmPassword:{ ID: "", CN: "确定密码", TR: "Confirm password" },
        realName:{ ID: "", CN: "用户名", TR: "Real Name" },
        corpName:{ ID: "", CN: "公司名称", TR: "Corporate Name" },
        corpAddress:{ ID: "", CN: "公司地址", TR: "Company address" },

        formValidate: {
            email0: { ID: 780, CN: "请输入邮箱", TR: "Input Email" },
            email1: { ID: 2398, CN: "请输入正确的邮箱格式", TR: "Please enter the correct email format" },
            TelPhone0: { ID: 2399, CN: "请输入手机号码", TR: "Please enter your mobile phone number" },
            TelPhone1: { ID: 2400, CN: "请输入正确的手机号码格式", TR: "Please enter the correct mobile phone number format" },
            pass0: { ID: 2401, CN: "请输入密码", TR: "Please input a password" },
            pass1: { ID: 2402, CN: "请输入密码长度为6~18位", TR: "Please enter a password with a length of 6 ~ 18 digits" },
            pass2: { ID: 2403, CN: "请再次输入密码", TR: "Please enter the password again" },
            pass3: { ID: 2404, CN: "两次密码输入不一样", TR: "The two passwords are different" },
            loginName0: { ID: 2405, CN: "请输入登录名", TR: "Please enter login name" },
            loginName1: { ID: 2406, CN: "请输入登录名长度为6~18位", TR: "Please enter a login with a length of 6 ~ 18 characters" },
            userName0: { ID: 407, CN: "请输入用户姓名", TR: "Please enter user name" },
            corpName0: { ID: 2408, CN: "请输入公司名称", TR: "Please enter company name" },
            corpAddress0:{ ID: "", CN: "请输入公司地址", TR: "Please enter the company address" },
        }
    },
    // 头部header栏
    header: {
        serieslist: { ID: 1, CN: "电子样本", TR: "Product Book" },
        selparas: { ID: 5, CN: "参数选型", TR: "Parameter Selection" },
        lang: { ID: 451, CN: "语言", TR: "Language" },
        cn: { ID: 721, CN: "中文", TR: "Chinese" },
        en: { ID: 297, CN: "英文", TR: "English" },
        ko: { ID: 298, CN: "韩文", TR: "Korean" },
        es: { ID: 2462, CN: "西班牙文", TR: "Spanish" },
        russian: { ID: 303, CN: "俄文", TR: "Russian" },
        Applications: { ID: 2409, CN: "其他应用", TR: "Other applications" },
        danji: { ID: 2410, CN: "单机版", TR: "Stand alone" },
        android: { ID: 2411, CN: "安卓", TR: "Android" },
        ios: { ID: 2412, CN: "苹果", TR: "IOS" },
        wechat: { ID: 2413, CN: "微信", TR: "Wechat" },
        userInfo: { ID: 23, CN: "账户信息", TR: "Information" },
        logout: { ID: 714, CN: "退出登录", TR: "Exit" },
        collectList: { ID: 2508, CN: "收藏列表", TR: "CollectList" },
        compareList: { ID: 856, CN: "比较列表", TR: "CompareList" },
        collect: { ID: 1702, CN: "收藏", TR: "Collect" },
        addCollect: { ID: 2509, CN: "加入收藏", TR: "AddCollect" },
        deleteCollect: { ID: 858, CN: "清除收藏", TR: "DeleteCollect" },
        compare: { ID: 701, CN: "比较", TR: "Compare" },
        addCompare: { ID: 855, CN: "加入比较", TR: "AddCompare" },
        deleteCompare: { ID: 857, CN: "清除比较", TR: "DeleteCompare" },

        comparisonAdded: { ID: 2510, CN: "已经加入比较列表", TR: "comparison added list" },
        addComparSueecss: { ID: 2511, CN: "加入比较列表成功", TR: "successfully added to the comparison list" },
        clearComparList: { ID: 2512, CN: "确认清除比较列表", TR: "confirm to clear the comparison list" },
        clearComparSuccess: { ID: 2513, CN: "清除比较列表成功", TR: "the comparison list was cleared successfully" },
        confirmDelCompar: { ID: 2514, CN: "确认删除此型号的比较", TR: "confirm to delete the comparison of this model" },
        deleteSuccess: { ID: 2515, CN: "删除成功", TR: "deleted successfully" },
        confirmDelCollect: { ID: 2516, CN: "确认删除此收藏", TR: "are you sure you want to delete this collection" },
        confirmClearCollect: { ID: 2517, CN: "确认清除收藏", TR: "confirm to clear the collection" },
        clearCollectSueecss: { ID: 2518, CN: "清除成功", TR: "Cleared successfully" },
        collecExists: { ID: 2519, CN: "收藏已存在", TR: "collection already exists" },
        collectSuccess: { ID: 2520, CN: "收藏成功", TR: "Collection success" },

        login: { ID: 681, CN: "登录", TR: "Login" },
        information: { ID: 2521, CN: "个人信息", TR: "Information" },
        tipInfo: { ID: 2522, CN: "您确定要退出系统吗", TR: "Are you sure you want to exit the system" },
        tip: { ID: 285, CN: "提示", TR: "tip" },
        define: { ID: 124, CN: "确定", TR: "ok" },
        cancel: { ID: 125, CN: "取消", TR: "cancel" },
        collectTip: { ID: 2523, CN: "收藏列表为空", TR: "Collection list is empty" },
        compareTip: { ID: 2524, CN: "比较列表至少需要两项", TR: "The comparison list requires at least two items" },

        accountInfo: { ID: 2525, CN: "账户信息", TR: "Account Info" },
        baseInfo: { ID: 424, CN: "基本信息", TR: "Base Info" },
        realName: { ID: 638, CN: "真实姓名", TR: "Real Name" },
        account: { ID: 2526, CN: "账号", TR: "Acccount" },
        telPhoneNumber: { ID: 379, CN: "手机号", TR: "TelPhone Number" },
        email: { ID: 1801, CN: "邮箱", TR: "E-mail" },
        corpName: { ID: 102, CN: "公司名称", TR: "CorpName" },
        corpAddress: { ID: 722, CN: "公司地址", TR: "CorpAddress" },
        userSetting:{ ID: "",CN:"用户设置", TR: "User settings" }

    },
    // 底部footer 栏
    footer: {
        version:{ ID: "",CN:"版本", TR: "Version" },
        technical: { ID: 709, CN: "技术支持：上海义维流体科技有限公司", TR: "Technical support: Shanghai EvenTech Fluid Technology Co. Ltd." },
        technicalAddres: { ID: 2415, CN: "备案号为：沪ICP备14049296号-2", TR: "The filing number is 沪ICP备14049296号-2-2" },
        copyright: { ID: 2416, CN: "版权所有：上海义维流体科技有限公司", TR: "Copyright：Even Technology Co., Ltd" },
        copyrightAddres: { ID: 2417, CN: "地址：上海市闵行区江月路999号1幢615", TR: "Address: 615, building 1, No. 999, Jiangyue Road, Minhang District, Shanghai" },
        tips: { ID: 2527, CN: "数据仅供参考：如有修改恕不通知 版权所有，盗版必究", TR: "The data is for reference only: in case of any modification, we will not notify the copyright owner, and piracy will be prosecuted" }
    }

}