module.exports = {
    // 电子样本
    ebookPage: {
        serieslist: { ID: 1, CN: "电子样本", TR: "Libro de productos" },
        motorFrequency: { ID: 72, CN: "电机频率", TR: "Frecuencia del motor" },
        displayMode: { ID: 670, CN: "显示方式", TR: "Modo de visualización" },
        list: { ID: 671, CN: "列表", TR: "Lista" },
        grid: { ID: 672, CN: "网格", TR: "Red" },
        keyWord: { ID: 667, CN: "关键词", TR: "Palabra clave" },
        keyWordRule: { ID: 171, CN: "请输入关键字", TR: "Introduzca la palabra clave" },
        view: { ID: 250, CN: "查询", TR: "Vista" },
        details: { ID: 999, CN: "详情", TR: "detalle" },


        pumpList: { ID: 122, CN: "水泵列表", TR: "Lista de bombas" },
        spectrumList: { ID: 6, CN: "系列型谱", TR: "Lista de espectro" },
        modelDrawing: { ID: 182, CN: "实物图", TR: "Dibujo de modelo" },
        seriesDescription: { ID: 144, CN: "系列说明", TR: "Descripción de la serie" },
        seriesDocuments: { ID: 693, CN: "系列文档", TR: "Documentos de la serie" },
        video: { ID: 694, CN: "详情视频", TR: "Video" },
        model_3D: { ID: 131, CN: "三维模型", TR: "Modelo 3D" },
        export: { ID: 276, CN: "导出", TR: "Exportar" },
        no: { ID: 695, CN: "序号", TR: "Número" }
    },
    //选型模块
    selectPage: {
        selparas: { ID: 5, CN: "", TR: "Selección de parámetro" },
        selResult: { ID: 2348, CN: "选型结果", TR: "Resultados de la selección" },
        index: { ID: 1437, CN: "首页", TR: "Página de inicio" },
        selRow: { ID: 736, CN: "列选择", TR: "Selección de columna" },
        export: { ID: 276, CN: "导出", TR: "Exportar" },
        print: { ID: 192, CN: "打印", TR: "Impresión" },
        previous: { ID: 367, CN: "上一步", TR: "Paso anterior" },
        physical: { ID: 184, CN: "实物图", TR: "Dibujo de modelo" },
        series: { ID: 253, CN: "系列", TR: "Series" },
        model: { ID: 100, CN: "型号", TR: "Modelo" },
        diagram: { ID: 781, CN: "曲线图", TR: "Gráfico de curva" },
        flow: { ID: 48, CN: "流量", TR: "Flujo" },
        head: { ID: 49, CN: "扬程", TR: "Elevar" },
        shaftPower: { ID: 109, CN: "轴功率", TR: "Potencia en el eje" },
        efficiency: { ID: 108, CN: "效率", TR: "Eficiencia" },
        npsh: { ID: 110, CN: "汽蚀", TR: "NPSH" },
        speed: { ID: 114, CN: "转速", TR: "Velocidad" },
        impellerDiameter: { ID: 223, CN: "叶轮直径", TR: "Diámetro del impulsor" },
        details: { ID: 999, CN: "详情", TR: "detalle" },

        quickSelection: { ID: 2349, CN: "快速选型", TR: "Selección rápida" },
        advancedSelection: { ID: 2350, CN: "高级选型", TR: "Selección avanzada" },
        workingConditions: { ID: 2351, CN: "工况条件", TR: "Condiciones de funcionamiento" },
        productSeries: { ID: 2352, CN: "产品系列", TR: "Serie de productos" },
        driveType: { ID: 1082, CN: "驱动类型", TR: "Tipo de conducción" },
        setFrequency: { ID: 2353, CN: "设置频率", TR: "Establecer frecuencia" },
        moreDesignPoints: { ID: 1429, CN: "更多设计点", TR: "Más puntos de dise?o" },
        hide: { ID: 2354, CN: "隐藏", TR: "Ocultar" },
        secondDpFlow: { ID: 2355, CN: "第二设计点流量", TR: "Flujo del segundo punto de diseño" },
        secondDpHead: { ID: 2356, CN: "第二设计点扬程", TR: "Elevación del segundo punto de diseño" },
        thirdDpFlow: { ID: 2357, CN: "第三设计点流量", TR: "Flujo del tercer punto de diseño" },
        thirdDpHead: { ID: 2358, CN: "第三设计点扬程", TR: "Elevación del tercer punto de diseño" },
        firePump: { ID: 2359, CN: "消防泵", TR: "Bomba contraincendios" },
        motorPoleNum: { ID: 1595, CN: "电机级数", TR: "Serie de motores" },
        moreSet: { ID: 2360, CN: "更多设置", TR: "Más ajustes" },
        put: { ID: 674, CN: "收起", TR: "Colapso" },
        motorPowerStd: { ID: 2361, CN: "电机功率标准", TR: "Norma de potencia del motor" },
        mediaName: { ID: 382, CN: "介质名称", TR: "Nombre del medio" },
        cleanWater: { ID: 465, CN: "清水", TR: "Clean Water" },
        maxTemperature: { ID: 2362, CN: "最高温度", TR: "Temperatura máxima" },
        customMediaProp: { ID: 2363, CN: "自定义介质属性", TR: "Propiedades de los medios personalizados" },
        maxDensity: { ID: 75, CN: "最大密度", TR: "Densidad máxima" },
        mediumViscosity: { ID: 77, CN: "介质粘度", TR: "Viscosidad media" },
        nextStep: { ID: 368, CN: "下一步", TR: "Próximo" },
        selectAll: { ID: 97, CN: "全选", TR: "Seleccionar todo" },
        reselect: { ID: 366, CN: "取消全选", TR: "Volver a seleccionar" },
        modelList: { ID: 443, CN: "型号列表", TR: "Lista de modelo" },
        byType: { ID: 2364, CN: "按类型", TR: "Por tipo" },
        byIndustry: { ID: 2365, CN: "按行业", TR: "Por industria" },
        type: { ID: 245, CN: "类型", TR: "Tipo" },
        applicationArea: { ID: 2366, CN: "应用领域", TR: "Ámbito de aplicación" },
        flowRule: { ID: 142, CN: "请输入流量", TR: "Ingrese el flujo y el cabezal" },
        headRule: { ID: 1164, CN: "请输入扬程", TR: "Ingrese el cabezal por favor" },
        seriesRule: { ID: 2367, CN: "至少选择一个系列", TR: "Seleccione al menos una serie" },
        dieselEngineSpeedRule: { ID: 2368, CN: "请输入柴油机转速", TR: "Introduzca la velocidad del motor diesel" },
        dieselEngineSpeed: { ID: 2369, CN: "柴油机转速", TR: "Velocidad del motor diesel" },

    },
    //详情页面
    detailPage: {
        report: { ID: 702, CN: "报告", TR: "Informe" },
        share: { ID: 703, CN: "分享", TR: "Cuota" },
        costAnalysis: { ID: 571, CN: "成本分析", TR: "Análisis de costos" },
        medium: { ID: 1434, CN: "介质", TR: "Medio" },
        model_3D: { ID: 131, CN: "三维模型", TR: "Modelo 3D" },
        scanQRShare: { ID: 2400, CN: "扫描二维码分享", TR: "Escaneo de código bidimensional compartido" },
        view: { ID: 250, CN: "查询", TR: "Vista" },

        close: { ID: 460, CN: "关闭", TR: "Cerrar" },
        setUp: { ID: 470, CN: "设定", TR: "Configurar" },
        flowUnits: { ID: 2134, CN: "流量单位", TR: "Flow Units" },
        headUnit: { ID: 2135, CN: "扬程单位", TR: "Head Unit" },
        powerUnit: { ID: 2401, CN: "功率单位", TR: "Power Unit" },
        exitFull: { ID: 2402, CN: "退出全屏", TR: "Exit Full" },
        partNo: { ID: 158, CN: "产品编号", TR: "Parte número" },
        materialGroup: { ID: 1712, CN: "材料组", TR: "Grupo de materiales" },
        material: { ID: 1215, CN: "材料", TR: "Material" },

        selectionReport: { ID: 236, CN: "选型报告", TR: "Informe de selección" },
        lang: { ID: 450, CN: "语言", TR: "Idioma" },
        fileFormat: { ID: 1532, CN: "文件格式", TR: "File Format" },
        buildFile: { ID: 2388, CN: "生成文件", TR: "Bulid File" },
        customerInfo: { ID: 795, CN: "客户信息", TR: "Customer Info" },
        orderInformation: { ID: 884, CN: "订单信息", TR: "Order Information" },
        setReportContent: { ID: 889, CN: "设置报告内容", TR: "Set report content" },
        projectInformation: { ID: 89, CN: "项目信息", TR: "Project Information" },
        productName: { ID: 881, CN: "产品名称", TR: "Product name" },
        projectCode: { ID: 1027, CN: "项目编号", TR: "Project Code" },
        projectName: { ID: 800, CN: "项目名称", TR: "Project name" },
        itemNO: { ID: 801, CN: "工位编号", TR: "Item NO" },
        customerInfo: { ID: 795, CN: "客户信息", TR: "Customer Info" },
        customerName: { ID: 883, CN: "客户名称", TR: "Customer Name" },
        customerAddress: { ID: 968, CN: "客户地址", TR: "Customer Address" },
        contactPerson: { ID: 766, CN: "联系人", TR: "Contact Person" },
        contactInformation: { ID: 35, CN: "联系方式", TR: "Contact Information" },
        email: { ID: 1801, CN: "邮箱", TR: "Email" },
        website: { ID: 1082, CN: "网址", TR: "Website" },
        country: { ID: 1083, CN: "国家", TR: "Country" },
        orderInformation: { ID: 1798, CN: "订单信息", TR: "Order Information" },
        price: { ID: 886, CN: "产品单价", TR: "Order Information" },
        pumpPeformance: { ID: 1796, CN: "订单号", TR: "Pump Peformance" },
        purchaseQuantity: { ID: 888, CN: "购买数量", TR: "Purchase Quantity" },
        orderNote: { ID: 885, CN: "订单备注", TR: "Order Note" },
        pageReportSet: { ID: 2397, CN: "页面报告设置", TR: "Page report settings" },
        isBWDispplay: { ID: 820, CN: "是否黑白显示曲线", TR: "Black and white display" },
        dataAndCurve: { ID: 817, CN: "数据表和曲线", TR: "The data sheet and performance curve" },
        curveGraph: { ID: 781, CN: "曲线图", TR: "Curve graph" },
        sizePicture: { ID: 705, CN: "尺寸图", TR: "Size Picture" },
        assemblyDrawing: { ID: 133, CN: "安装图", TR: "Assembly Drawing" },
        modelDrawing: { ID: 182, CN: "实物图", TR: "Model Drawing" },
        modelDrawinUnit: { ID: 2398, CN: "实物图(机组)", TR: "Model Drawing(unit)" },
        structureDrawing: { ID: 184, CN: "结构图", TR: "Structure Drawing" },
        offerText: { ID: 814, CN: "报价文本", TR: "Offer Text" },
        companyName: { ID: 1011, CN: "公司抬头", TR: "Company Name" },
        companyWebsite: { ID: 1799, CN: "公司网址", TR: "Company Website" },
        performanceCurve: { ID: 217, CN: "性能曲线", TR: "Performance Curve" },
        assemblyDrawingPart: { ID: 2389, CN: "安装图(单泵)", TR: "Assembly Drawing(single Pump)" },
        assemblyDrawingSys: { ID: 2390, CN: "安装图(机组)", TR: "Assembly Drawing(unit)" },
        physical: { ID: 184, CN: "实物图", TR: "Model Drawing" },
        parameter: { ID: 111, CN: "参数", TR: "Parameter" },
        data: { ID: 2056, CN: "数据", TR: "Data" },
        unit: { ID: 527, CN: "单位", TR: "Unit" },
        operatingParameters: { ID: 2157, CN: "运行参数", TR: "Operating parameters" },
        parameterQuery: { ID: 2391, CN: "参数查询", TR: "Parameter Query" },
        installation: { ID: 2201, CN: "安装信息", TR: "Installation" },
        motor: { ID: 2202, CN: "电机信息", TR: "Motor" },
        documents: { ID: 693, CN: "系列文档", TR: "Documents" },
        properties: { ID: 229, CN: "属性", TR: "Properties" },
        material: { ID: 2170, CN: "材质", TR: "Material" },
        flow: { ID: 48, CN: "流量", TR: "Flow" },
        head: { ID: 49, CN: "扬程", TR: "Head" },
        shaftPower: { ID: 109, CN: "轴功率", TR: "Shaft Power" },
        efficiency: { ID: 108, CN: "效率", TR: "Efficiency" },
        npsh: { ID: 110, CN: "汽蚀", TR: "NPSH" },
        speed: { ID: 114, CN: "转速", TR: "Speed" },
        coefficient: { ID: 1143, CN: "系数", TR: "Coefficient" },
        parameterSelection: { ID: 5, CN: "参数设计", TR: "Parameter Selection" },
        allowableRegionMax: { ID: 2392, CN: "允许区域(大)", TR: "Allowable Region(max)" },
        allowableRegionMin: { ID: 2393, CN: "允许区域(小)", TR: "Allowable Region(max)" },
        optimalAreaMax: { ID: 2394, CN: "最优区域(大)", TR: "Optimal Area(max)" },
        optimalAreaMin: { ID: 2395, CN: "最优区域(小)", TR: "Optimal Area(min)" },
        closeDeadCenter: { ID: 2396, CN: "关死点", TR: "Close Dead Center" },
        bestAdvantage: { ID: 2402, CN: "最优点", TR: "Best Advantage" },
        flowPoint150: { ID: 2403, CN: "流量150%点", TR: "Flow 150% point" },
        powerPointMax: { ID: 2404, CN: "最大功率点", TR: "Maximum Power Point" },

    },
    //首页
    indexPage: {
        corpIntroduction: { ID: 30, CN: "公司介绍", TR: "Introducción de la Compa?ía" },
        enterpriseHonors: { ID: 32, CN: "企业荣誉", TR: "Honores Empresariales" },
        enterpriseStyle: { ID: 2370, CN: "企业风貌", TR: "Características de la empresa" },
        salesNetwork: { ID: 31, CN: "销售网络", TR: "Red de ventas" },
        performanceSelection: { ID: 2371, CN: "水力性能选型", TR: "Selección del tipo de rendimiento hidráulico" },
        productSearch: { ID: 786, CN: "产品搜索", TR: "Búsqueda de Producto" },
        contactinformation: { ID: 35, CN: "联系方式", TR: "Información del contacto" },
        ebook: { ID: 1, CN: "电子样本", TR: "Libro de productos" },
        pdfFile: { ID: 1714, CN: "纸质样本", TR: "Archivo PDF" },
        application: { ID: 2372, CN: "应用", TR: 'Aplicación' },
        intelligentSelection: { ID: 68, CN: "智能选型", TR: "Selección de bomba" },
        firePump: { ID: 2359, CN: "消防泵", TR: "Bomba contraincendios" },
        flow: { ID: 48, CN: "流量", TR: "Flujo" },
        head: { ID: 49, CN: "扬程", TR: "Elevar" },
        type: { ID: 245, CN: "类型", TR: "Tipo" },
        more: { ID: 746, CN: "更多", TR: "Más" },
        nextStep: { ID: 368, CN: "下一步", TR: "Próximo" },
    },
    // 登录
    simpleLoginPage: {
        login: { ID: 679, CN: "登录", TR: "Registrarse" },
        register: { ID: 380, CN: "注册", TR: "Registrarse" },
        AccountLogin: { ID: 382, CN: "账户登录", TR: "Inicio de sesión de usuario" },
        CodeScanningLogin: { ID: 2373, CN: "扫码登录", TR: "Inicio de sesión de barrido" },
        registerInfo: { ID: 2374, CN: "如果您还没有加入我们，可以点击下方的注册成为我们的一员", TR: "Si aún no se ha unido a nosotros, puede hacer clic en el registro de abajo para convertirse en uno de nosotros" },
        registerInfoTitle: { ID: 2375, CN: "新用户", TR: "Nuevos usuarios" },
        loginInfo: { ID: 2376, CN: "如果您已经是我们中的一员可以点击下方的登录，进入到我们的平台", TR: "Si usted ya es uno de nosotros puede hacer clic en el inicio de sesión de abajo para entrar en nuestra plataforma" },
        loginInfoTitle: { ID: 2377, CN: "我们中的一员", TR: "Uno de nosotros" },
        userType: { ID: 2378, CN: "用户类型", TR: "Tipo de usuario" },
        loginName: { ID: 2379, CN: "账户名", TR: "Nombre de la cuenta" },
        password: { ID: 378, CN: "密码", TR: "Contrase?a" },
        userType0: { ID: 381, CN: "内部人员", TR: "Personal interno" },
        userType1: { ID: 2380, CN: "外部人员", TR: "Personal externo" },
        formValidate: {
            email0: { ID: 2381, CN: "请输入邮箱", TR: "Por favor, introduzca el buzón de correo" },
            email1: { ID: 2382, CN: "请输入正确的邮箱格式", TR: "Introduzca el formato correcto del buzón de correo" },
            TelPhone0: { ID: 2383, CN: "请输入手机号码", TR: "Por favor, introduzca el número de teléfono móvil" },
            TelPhone1: { ID: 2384, CN: "请输入正确的手机号码格式", TR: "Introduzca el formato correcto del número de teléfono móvil" },
            pass0: { ID: 2385, CN: "请输入密码", TR: "Introduzca la contraseña" },
            pass1: { ID: 2386, CN: "请输入密码长度为6~18位", TR: "Introduzca una contraseña de 6 a 18 bits" },
            pass2: { ID: 2387, CN: "请输再次入密码", TR: "Dos contraseñas diferentes" },
            pass3: { ID: 2388, CN: "两次密码输入不一样", TR: "The two passwords are different" },
            loginName0: { ID: 2389, CN: "请输入登录名", TR: "Introduzca el nombre de usuario" },
            loginName1: { ID: 2390, CN: "请输入登录名长度为6~18位", TR: "Introduzca la longitud del nombre de usuario de 6 a 18 bits" },
            userName0: { ID: 2391, CN: "请输入用户姓名", TR: "Introduzca el nombre del usuario" },
            corpName0: { ID: 2392, CN: "请输入公司名称", TR: "Introduzca el nombre de la empresa" },
        }
    },
    // 头部header栏
    header: {
        serieslist: { ID: 1, CN: "电子样本", TR: "Libro de productos" },
        selparas: { ID: 5, CN: "参数设计", TR: "Selección de parámetro" },
        lang: { ID: 450, CN: "语言", TR: "Idioma" },
        cn: { ID: 726, CN: "中文", TR: "Chino" },
        en: { ID: 295, CN: "英文", TR: "Inglés" },
        russian: { ID: 301, CN: "俄文", TR: "Ruso" },
        es: { ID: 2345, CN: "西班牙文", TR: "Espa?ol" },
        Applications: { ID: 2393, CN: "其他应用", TR: "Otras aplicaciones" },
        danji: { ID: 2394, CN: "单机版", TR: "Versión independiente" },
        android: { ID: 2354, CN: "安卓", TR: "Android" },
        ios: { ID: 2355, CN: "苹果", TR: "IOS" },
        wechat: { ID: 2356, CN: "微信", TR: "Wechat" },
        userInfo: { ID: 2395, CN: "账户信息", TR: "Información" },
        logout: { ID: 719, CN: "退出登录", TR: "Salida" },
        login: { ID: 679, CN: "登录", TR: "Registrarse" },
    },
    // 底部footer 栏
    footer: {
        technical: { ID: 2396, CN: "技术支持：上海义维流体科技有限公司", TR: "Soporte técnico: Even Technology Co., Ltd." },
        technicalAddres: { ID: 2397, CN: "备案号为：沪ICP备14049296号-2", TR: "Número de registro 沪ICP备14049296号-2-2" },
        copyright: { ID: 2398, CN: "版权所有：上海义维流体科技有限公司", TR: "Copyright: Even Technology Co., Ltd." },
        copyrightAddres: { ID: 2399, CN: "地址：上海市闵行区江月路999号1幢615", TR: "Dirección: 615 Building 1, no. 999 jiangyue Road, Minhang District, Shanghai" },
    }

}