<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <div class="box">
      <div style="width: 100%; margin: 0 auto; height: calc(100% - 0px)">
        <router-view v-slot="{ Component }">
          <transition>
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>



<script>
import pageHead from "./head.vue";
import pageFooter from "./footer.vue";

export default {
  name: "index",
  components: {
    pageHead,
    pageFooter,
  },
  provide() {
        return {
            reload: this.reload,
        };
    },
  data() {
    return {
      zh_key: 1,
      en_key: 2,
      isRouterAlive: true
    };
  },
  created() {
    // this.getUserInfo();

    //console.log(this.$router,142)

    //监听路由变化  记录退出登录之间的页面路径
    this.$router.beforeEach((to, from, next) => {
      //console.log(to,144);
      if (to.fullPath != "/login/simpleLogin") {
        this.$store.commit("instante/account/preLoginPageRoute", to.fullPath);
        //const { pagefullPath } = this.$store.state.instante.account;
        //console.log(pagefullPath, 152);
      }

      next();
    });
  },
  mounted() {

    //   // 页面标题--默认产业大脑    html(富文本)  非必填
    //   pageTitle: "高效水力模型",

    //   // 面包屑（自带首页）    array 非必填
    //   crumbsList: [],
    //   // 退出前的操作  function 非必填
    //   beforeLoginOut: () => {
    //     console.log("退出前的操作");
    //     // document.cookie = "companyName=";
    //     // document.cookie = "userId=";
    //   },
    // });

    function getdescookie(strcookie, matchcookie) {
      var getMatchCookie;
      var arrCookie = strcookie.split(";");
      for (var i = 0; i < arrCookie.length; i++) {
        var arr = arrCookie[i].split("=");
        if (matchcookie == arr[0].trim()) {
          getMatchCookie = arr[1];
          break;
        }
      }
      return getMatchCookie;
    }
  },
  computed: {
    noFrame: function () {
      //初始化时noFrame是undefined，需要设置为不在框架内显示，否则会导致先显示布局页面再显示当前路由页面的问题
      //比如登录页面会先看到框架皮肤页面，再看到登录页
      if (typeof this.$route.meta.noFrame === "undefined") return true;
      return this.$route.meta.noFrame;
    },
  },
  methods: {
    getUserInfo() {
      let _this = this;
      let url =
        _this.$globalConfig.WebApiUrl.MainUrl +
        "DingdangAuthor/GetLoginUserInfo";

      this.$axios
        .get(url)
        .then(function (res) {
          console.log(res, 1006);
          if (res.data.Code != 0) {
            // _this.$message.error(res.data.Data);
            // return  // 本地测试获取不到数据  放到服务器时要取消注释
          }

          let userInfo = res.data.Data;

          let companyName = userInfo.CompanyName;
          // companyName=companyName==null?"义维科技":companyName ;//本地测试用  product需要注释掉
          if (companyName) {
            companyName = encodeURIComponent(companyName);
            document.cookie = "companyName=" + companyName;
          }
          let userId = userInfo.ExternalId;
          // userId=userId==null?"4374417043912503090":userId ;//本地测试用  product需要注释掉
          if (userId) {
            document.cookie = "userId=" + userId;
          }

          // console.log(document.cookie, 37);
        })
        .catch(function (err) {
          // _this.$message.error("发送失败");
        });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss">
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  overflow: auto;
}

// html {
//   font-size: calc(100vw / 19.2) !important;
// }
// * {
//   font-size: 14px;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow: auto;
}

.box {
  position: relative;
  height: 100%;
  background: #fff;
}

section {
  height: 100%;
}

.el-loading-spinner {
  background-image: url("../assets/img/loadingGIF.gif");
  background-repeat: no-repeat;
  background-size: 150px 150px;
  height: 100%;
  background-position: center;
  top: 0;
}

.el-loading-spinner .circular {
  display: none;
}

.el-message {
  // margin-top:100px!important;
  z-index: 99999999 !important;
}
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>