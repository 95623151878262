import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/common/lang/zh'
import en from '@/common/lang/en'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(VueI18n);
const messages = {
    en: Object.assign(en, enLocale),
    zh: Object.assign(zh, zhLocale)
}

let i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'zh',
    messages,
})

export default i18n

 
