//选型模块
import db from '../db' //引入本地数据库localStrong
// 所有选型模块的信息

const state = {
    /**参数选型1 */
    ByParas: db.get('ByParas') || {},
    /**相似设计*/
    bySimuD: db.get('bySimu') || {}
}

const actions = {

}

const mutations = {
    ByParas(state, current) {
        db.set('ByParas', current)
        Object.assign(state.ByParas, current)
    },

    BySimu(state, current) {
        db.set('bySimu', current)
        Object.assign(state.bySimuD, current)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}