import Vue from 'vue'
//自定义修改网页title
Vue.directive('myTitle', {
    inserted: function (el, binding) {
      const { value } = binding
      // 方式1，直接给v-myTitle标签的 data-title 属性赋值
      if (el.dataset.title) { 
        document.title = el.dataset.title
      } 
      // 方式2，通过指令传参（{{xxx}}）
      else if (value && value.title) { 
        document.title = value.title
      }
    },
    update (el, binding) {
      const { value } = binding
      if (el.dataset.title) {
        document.title = el.dataset.title
      } else if (value && value.title) {
        document.title = value.title
      }
    }
  })