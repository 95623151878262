/**本地存储库 */

export default {
  /**
   * @description 设置
   */
  set(key, value) {
    localStorage.setItem(`${key}`, JSON.stringify(value))
  },
  /**
   * @description 获取
   */
  get(key) {
    return JSON.parse(localStorage.getItem(`${key}`))
  },
  /**
   * @description 删除令牌
   */
  remove(key) {
    localStorage.removeItem(`${key}`)
  },
  /**
   * @description 清除全部
   */
  clearAll() {
    localStorage.clear()
  },
}
