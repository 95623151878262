<template>
  <header class="head_box">
      <div class="left">
          <div class="img_box" style="
        width: 100px;
        height: 46px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      " @click="goHome">
              <img :src="titleLogoSrc" />
          </div>
          <slot></slot>
      </div>
      <div class="right">
          <el-dropdown trigger="click" style="width: 140px">
              <!-- <span class="iconfont iconduoyuyan"></span> -->
              <span class="el-dropdown-link" style="cursor: pointer">
                  <i class="iconfont iconduoyuyan"></i>
                  {{ $t("header.lang.TR") }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item @click.native="toggleLang('cn')" :disabled="$i18n.locale == 'cn'">
                          <svg class="icon" style="font-size: 28px" aria-hidden="true">
                              <use xlink:href="#iconzhongwen1" />
                          </svg>
                          {{ $t("header.cn.TR") }}
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="toggleLang('en')" :disabled="$i18n.locale == 'en'">
                          <svg class="icon" style="font-size: 28px; margin-right: 1px" aria-hidden="true">
                              <use xlink:href="#iconmeiguo" />
                          </svg>
                          {{ $t("header.en.TR") }}(US)
                      </el-dropdown-item>
                      <el-dropdown-item v-if="isHaveEN_GB" @click.native="toggleLang('en_gb')"
                          :disabled="$i18n.locale == 'en_gb'">
                          <svg class="icon" style="font-size: 28px; margin-right: 1px" aria-hidden="true">
                              <use xlink:href="#iconyingguo-duo" />
                          </svg>
                          {{ $t("header.en.TR") }}(GB)
                      </el-dropdown-item>
                      <el-dropdown-item v-if="isHaveKO" @click.native="toggleLang('ko')"
                          :disabled="$i18n.locale == 'ko'">
                          <svg class="icon" style="font-size: 24px; margin-right: 1px" aria-hidden="true">
                              <use xlink:href="#iconhanguo" />
                          </svg>
                          {{ $t("header.ko.TR") }}
                      </el-dropdown-item>
                      <el-dropdown-item v-if="isHaveRU" @click.native="toggleLang('ru')"
                          :disabled="$i18n.locale == 'ru'">
                          <svg class="icon" style="font-size: 24px; margin-right: 1px" aria-hidden="true">
                              <use xlink:href="#iconeguo" />
                          </svg>
                          {{ $t("header.russian.TR") }}
                      </el-dropdown-item>
                  </el-dropdown-menu>
              </template>
          </el-dropdown>

          <el-dropdown trigger="click" @command="excuteUserCmd">
              <span class="el-dropdown-link el-icon-user-solid">
                  <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item command="login" v-if="!m_isLoginStatus">
                          {{ $t("header.login.TR") }}
                      </el-dropdown-item>

                      <el-dropdown-item command="userinfo" icon="iconfont iconzhanghuxinxi" v-if="m_isLoginStatus">
                          {{ $t("header.userInfo.TR") }}
                      </el-dropdown-item>
                      <el-dropdown-item command="logout" icon="iconfont icontuichu" v-if="m_isLoginStatus">
                          {{ $t("header.logout.TR") }}
                      </el-dropdown-item>
                      <el-dropdown-item command="editorPwd" v-if="m_isLoginStatus" icon="el-icon-edit">{{
                      $t("indexPage.changePassword.TR") }}</el-dropdown-item>
                  </el-dropdown-menu>
              </template>
          </el-dropdown>
      </div>

      <el-dialog :title="$t('header.accountInfo.TR')" top="10vh" width="30%"
          :visible.sync="m_isVisibleUserInfoDialog">
          <div class="user_info_div">
              <div class="user_info_hd">
                  <h3 class="user_info_tit">{{ $t("header.baseInfo.TR") }}</h3>
              </div>
              <div class="user_info_bd">
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.realName.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.RealName }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.account.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.LoginName }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.telPhoneNumber.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.Telephone }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.email.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.Email }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.corpName.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.CorpName }}</p>
                          </div>
                      </div>
                  </div>
                  <div class="user_info_row">
                      <div class="user_info_row_tit">
                          <label>{{ $t("header.corpAddress.TR") }}:</label>
                      </div>
                      <div class="user_info_row_cont">
                          <div class="user_info_row_cont_col user_info_row_cont_col1">
                              <p class="text">{{ m_userInfo.CorpAddress }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </el-dialog>
  </header>
</template>

<script>
import languageHelper from "@/utils/languageHelper";
import languageMixin from "@/mixin/language";
export default {
  inject: ["reload"],
  mixins: [languageMixin],
  data() {
      return {
          isHaveKO: false, //是否有韩语
          isHaveRU: false, //是否有俄文
          isHaveEN_GB: false, //是否有 英文(英式)
          m_currentLanguage: 0,
          m_isLoginStatus: false,
          m_isVisibleUserInfoDialog: false, //用户信息弹框状态
          titleLogoSrc: "static/img/TitleLog.svg",
          m_userInfo: {
              UserID: "",
              LoginName: "",
              RealName: "",
              UserType: "",
              Code: "",
              Telephone: "",
              Email: "",
              CorpName: "",
              CorpAddress: "",
          },
      };
  },
  mounted() {
      let pageConfig = window.pageConfig.IndexPage;
      this.isHaveKO = pageConfig.Language.IsHaveKO;
      this.isHaveRU = pageConfig.Language.IsHaveRU;
      this.isHaveEN_GB = pageConfig.Language.IsHaveEN_GB;
      //  console.log(this.$i18n.locale,111 )
      this.m_currentLanguage = languageHelper.getTypeFromRout(
          window.location.href
      );
      var language_para_name = languageHelper.getParaNameByType(
          this.m_currentLanguage
      );
      //console.log(language_para_name,235)

      // this.titleLogoSrc = "static/img/TitleLog_" + language_para_name + ".svg";

      var userID = this.$store.state.instante.account.UserID;
      if (userID == null || userID == 0) {
          this.m_isLoginStatus = false;
      } else {
          this.m_isLoginStatus = true;
      }
  },
  methods: {
      //获取账户信息
      getUserInfo() {
          let UserType = this.$store.state.instante.account.UserType;
          let UserID = this.$store.state.instante.account.UserID;

          this.$axios({
              url: this.$globalConfig.WebApiUrl.MainUrl + "v1/UserInfo/GetUserInfo",
              params: {
                  UserType: UserType,
                  UserID: UserID,
              },
          })
              .then((res) => {
                  // console.log(res,302)
                  let result = res.data.Data;
                  this.m_userInfo.UserID = result.UserID;
                  this.m_userInfo.LoginName = result.LoginName;
                  this.m_userInfo.RealName = result.RealName;
                  this.m_userInfo.UserType = result.UserType;
                  this.m_userInfo.Code = result.Code;
                  this.m_userInfo.Telephone = result.Telephone;
                  this.m_userInfo.Email = result.Email;
                  this.m_userInfo.CorpName = result.CorpName;
                  this.m_userInfo.CorpAddress = result.CorpAddress;
              })
              .catch((err) => { });
      },
      //点击图片返回首页
      goHome() {
          var lang_url = languageHelper.getRouterUrlByType(this.m_currentLanguage);
          this.$router.push({
              path: "/" + lang_url + "/Index",
          });
      },
      //执行用户信息命令
      excuteUserCmd(cmd) {
          switch (cmd) {
              case "logout":
                  this.$confirm(
                      `${this.$t("header.tipInfo.TR")}`,
                      `${this.$t("header.tip.TR")}`,
                      {
                          confirmButtonText: `${this.$t("header.define.TR")}`,
                          cancelButtonText: `${this.$t("header.cancel.TR")}`,
                          type: "warning",
                      }
                  )
                      .then(() => {
                          this.$store.dispatch("instante/account/logout", this.$route.path);
                      })
                      .catch(() => { });
                  break;

              case "login":
                  this.$store.commit(
                      "instante/account/preLoginPageRoute",
                      this.$route.fullPath
                  );
                  this.$store.dispatch("instante/account/logout", this.$route.path);
                  break;

              case "userinfo":
                  let userID1 = this.$store.state.instante.account.UserID;
                  if (userID1 == null || userID1 == 0) return;

                  this.m_isVisibleUserInfoDialog = true;
                  if (this.m_userInfo.UserID == "") this.getUserInfo();
                  break;

              case "editorPwd":
                  let userID2 = this.$store.state.instante.account.UserID;
                  if (userID2 == null || userID2 == 0) return;

                  this.$router.push({
                      path: this.buildCurrentLanguageUrl(`/login/changePassword`),
                  });
                  break;
          }
      },
      //切换语言
      toggleLang(lang) {
          this.$emit("translateLang");

          if (lang == "cn") {
              this.$i18n.locale = "cn";
              this.gotoPage(this.$route.fullPath, this.$route.query, true, "CN");
              this.reload();
          } else if (lang == "en") {
              this.$i18n.locale = "en";
              localStorage.setItem("lang", "en");
              this.gotoPage(this.$route.fullPath, this.$route.query, true, "EN");

              // this.$router.go(0);
              this.reload();
          } else if (lang == "en_gb") {
              this.$i18n.locale = "en_gb";
              localStorage.setItem("lang", "en_gb");
              this.gotoPage(this.$route.fullPath, this.$route.query, true, "EN-GB");
              // this.$router.go(0);
              this.reload();
          } else if (lang == "ko") {
              this.$i18n.locale = "ko";
              this.$message({
                  message: "한국어로 전환!",
                  type: "success",
              });
              this.gotoPage(this.$route.fullPath, this.$route.query, true, "KO");
              // this.$router.go(0);
              this.reload();
          } else if (lang == "ru") {
              this.$i18n.locale = "ru";
              this.$message({
                  message: "Переключиться на русский язык!",
                  type: "success",
              });
              this.gotoPage(this.$route.fullPath, this.$route.query, true, "RU");
              // this.$router.go(0);
              this.reload();
          }
      },
      //
      gotoPage(url, query, replace, langUrl) {
          if (replace == null) replace = true;
          let full_url = "";
          let reg = new RegExp(
              languageHelper.getParaNameByType(this.m_currentLanguage),
              "g"
          );
          // console.log(this.m_currentLanguage, langUrl, 370);
          full_url = url.replace(reg, langUrl);
          // console.log(full_url, 375);
          this.$router.push({
              path: full_url,
              query: query,
              replace: replace,
          });
      },
  },
};
</script>

<style lang="scss">
.head_box {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 46px;
  padding: 0 10px;
  box-sizing: border-box;

  .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 46px;

      .img_box {
          width: 200px;
          height: 40px;

          img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
          }
      }
  }

  .right {
      display: flex;
      height: 46px;
      justify-content: flex-end;
      align-items: center;
  }

  .el-dropdown {
      color: #fff;
      font-size: 14px;
      padding-right: 0.200px;
      cursor: pointer;
  }

  .user_info_div {
      background: #fff;
      border: 1px solid #ddd;
      padding: 0rem 24px;
      margin-bottom: 9px;
      text-align: left;

      .user_info_hd {
          position: relative;
          min-height: 60px;

          .user_info_tit {
              font-size: 18px;
              display: inline-block;
              color: #636363;
              vertical-align: top;
              font-weight: 600;
          }
      }

      .user_info_bd {
          .user_info_row {
              padding: 0 0 0rem;
              zoom: 1;
              display: flex;
              align-items: center;
              min-height: 50px;

              .user_info_row_tit {
                  float: left;
                  color: #909090;
                  padding: 10px 0 9px;
                  width: 154px;

                  label {
                      font-size: 16px;
                  }
              }

              .user_info_row_cont {
                  font-size: 16px;
                  display: inline-block;

                  .user_info_row_cont_col {
                      font-size: 16px;
                      line-height: 40px;
                  }

                  .user_info_row_cont_col1 {
                      margin-right: 10px;
                  }

                  .text {
                      line-height: 28px;
                      padding: 7px 0 5px;
                      word-break: break-all;
                      font-size: 16px;
                  }
              }
          }
      }
  }
}
</style>