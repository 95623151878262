module.exports = {
    langType: "EN",
    message: {
        title: 'Sport Brands'
    },
    placeholder: {
        userName:"Please enter your user name",
        password:"Please enter your password",
        enter: 'Please type in your favorite brand'
    },
    ebook:{
        serieslist:"Part List",
    },
    select:{
        selparas:"Parame design",
        bySimuD:{
            selParas:'Similar design'
        }
    },
    lang:{
        lang:"language",
        zh:"Chinese",
        en:"English",
        russian:"Russian",
    },
    Applications:{
        Applications:"Other Applications",
        danji:"standalone version",
        android:"Android",
        ios:"IOS",
        wechat:"Wechat",
    },
    user:{
        userInfo:"userInfo",
        logout:"logout",
        login:"login",
    },
    simpleLogin:{
        login:"login",
        register:"register",
        AccountLogin:"AccountLogin",
        CodeScanningLogin:"CodeScanningLogin",
        registerInfo:"If you haven't joined us yet, you can click the registration below to become a member of us",
        registerInfoTitle:"new user",
        loginInfo:"If you are already one of us, you can click the login below to enter our platform",
        loginInfoTitle:"One of us",
        userType:"userType",
        loginName:"phoneNumber",
        password:"password",
        userType0:"Internal personnel",
        userType1:"External personnel",
        formValidate:{
            email0:"Please enter email address",
            email1:"Please enter the correct email format",
            TelPhone0:"Please enter your mobile phone number",
            TelPhone1:"Please enter the correct mobile phone number format",
            pass0:"Please input a password",
            pass1:"Please enter a password with a length of 6 ~ 18 digits",
            pass2:"Please enter the password again",
            pass3:"The two passwords are different",
            loginName0:"Please enter login",
            loginName1:"Please enter a login with a length of 6 ~ 18 characters",
            userName0:"Please enter user name",
            corpName0:"Please enter company name",
        },
    },
    footer:{
        technical:"Technical: Shanghai Eventech Fluid Technology Co. Ltd.",
        technicalAddres:"",
        copyright:"All right reserved：Shanghai Eventech Fluid Technology Co. Ltd.",
        copyrightAddres:"Address: 615, building 1, No. 999, Jiangyue Road, Minhang District, Shanghai",
    }
}