import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import skin from './modules/skin'
import token from './modules/token'
import select from './modules/select'

Vue.use(Vuex)
const store = new Vuex.Store({
        state: {},
        mutations: {},
        actions: {},
        modules: {
            instante: {
                namespaced: true,
                modules: {
                    account,
                    skin,
                    token,
                    select,
                },
            },
        }
    })
    //console.log(store.commit('instante/account/init',null,{ root: true }),27)
export default store