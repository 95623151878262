import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cn from '@/lang/tran/cn'
import en from '@/lang/tran/en'
import es from '@/lang/tran/es'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import esLocale from 'element-ui/lib/locale/lang/es'

Vue.use(VueI18n);

const messages = {
    en: Object.assign(en, enLocale),
    cn: Object.assign(cn, zhLocale),
    es: Object.assign(es, esLocale),
}
        
let i18n = new VueI18n({
    locale: 'cn',
    messages
})

var LanguageHelper = {
    i18n:i18n,
    //语言
    LocalizationType: {
        zhCN: 0, //zh-CN 简体中文(中国)
        zhTW: 1, //zh-TW 繁体中文(台湾地区)
        enUS: 2, //en-US 英语(美国)
        de: 3, //德文
        ja: 4, //日文
        ru: 5, //俄文
        ko: 6, //韩文
        fr: 7, //法文
        es: 8, //西班牙文 
        it: 9, //意大利文 
        pt: 10, //葡萄牙文 
        ca: 11, // 加泰罗尼亚文
        pl: 12, // 波兰文 
        nl: 13, // 荷兰文 
        el: 14 // 希腊文     
    },
    //
    getLocalizationTypeCNName(type) {
        let name = ""
        if (type == 0) {
            name = "简体中文"
        }
        if (type == 1) {
            name = "繁体中文"
        }
        if (type == 2) {
            name = "英语"
        }
        if (type == 3) {
            name = "德文"
        }
        if (type == 4) {
            name = "日文"
        }
        if (type == 5) {
            name = "俄文"
        }
        if (type == 6) {
            name = "韩文"
        }
        if (type == 7) {
            name = "法文"
        }
        if (type == 8) {
            name = "西班牙文"
        }
        if (type == 9) {
            name = "意大利文"
        }
        if (type == 10) {
            name = "葡萄牙文"
        }
        if (type == 11) {
            name = "加泰罗尼亚文"
        }
        if (type == 12) {
            name = "波兰文"
        }
        if (type == 13) {
            name = "荷兰文"
        }
        if (type == 14) {
            name = "希腊文"
        }
        return name;
    },
    //
    getCurrentType() {
        let local = window.location.href
        if (local.indexOf("/CN/") != -1 || local.indexOf("/cn/") != -1)
            return 0;
        if (local.indexOf("/EN/") != -1 || local.indexOf("/en/") != -1)
            return 2;
        if (local.indexOf("/ES/") != -1 || local.indexOf("/es/") != -1)
            return 8;
        return 0;
    },
    getCurrentUrl: function () {
        // var local = localStorage.getItem('locale') || 'cn';
        var local = window.location.href
        if (local.indexOf("/CN/") != -1 || local.indexOf("/cn/") != -1)
            return "CN";
        if (local.indexOf("/EN/") != -1 || local.indexOf("/en/") != -1)
            return "EN";
        if (local.indexOf("/ES/") != -1 || local.indexOf("/es/") != -1)
            return "ES";
        return "CN";
    },
    //获取当前的公司名称
    getCorpName() {
        var local = window.location.href
        if (local.indexOf("/CN/") != -1 || local.indexOf("/cn/") != -1)
            return window.globalConfig.CorpInfo.CorpShortName.CN;
        if (local.indexOf("/EN/") != -1 || local.indexOf("/en/") != -1)
            return window.globalConfig.CorpInfo.CorpShortName.EN;
        if (local.indexOf("/ES/") != -1 || local.indexOf("/es/") != -1)
            return "ES";
        return window.globalConfig.CorpInfo.CorpShortName.CN;
    },
    initialLocale_i18n(i18n) {
        let currentPath = path;
        if (currentPath.indexOf("CN") != -1) {
            i18n.locale = "cn";
        }
        if (currentPath.indexOf("EN") != -1) {
            this.$i18n.locale = "en";
        }
    },
    initI18nType(){
        var local = window.location.href
        if (local.indexOf("/CN/") != -1 || local.indexOf("/cn/") != -1) {
            i18n.locale = "cn";
        }
        if (local.indexOf("/EN/") != -1 || local.indexOf("/en/") != -1) {
            i18n.locale = "en";
        }
        //console.log(i18n.locale,143)
    },
}

export default LanguageHelper 