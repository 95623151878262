const LANGUAGE_TYPE_LIST = {
    zhCN: 0, //zh-CN 简体中文(中国)
    zhTW: 1, //zh-TW 繁体中文(台湾地区)
    enUS: 2, //en-US 英语(美国)
    enGB: 15,//en-US 英语(英国)
    de: 3, //德文
    ja: 4, //日文
    ru: 5, //俄文
    ko: 6, //韩文
    fr: 7, //法文
    es: 8, //西班牙文 
    it: 9, //意大利文 
    pt: 10, //葡萄牙文 
    ca: 11, // 加泰罗尼亚文
    pl: 12, // 波兰文 
    nl: 13, // 荷兰文 
    el: 14 // 希腊文     
}

class languageHelper {
    /**
    * @description 从路由获取语言
    */
    static getTypeFromRout(local) {
        if (local == null)
            return 0;
        local = local.toUpperCase();
        if (local.indexOf("/CN/") != -1)
            return 0;
        else if (local.indexOf("/EN/") != -1)
            return 2;
        else if (local.indexOf("/EN-GB/") != -1)
            return 15;
        else if (local.indexOf("/ENGB/") != -1)
            return 15;
        else if (local.indexOf("/ES/") != -1)
            return 8;
        else if (local.indexOf("/KO/") != -1)
            return 6;
        else if (local.indexOf("/RU/") != -1)
            return 5;
        else
            return 0;
    };
    /**
    * @description 获取语言名称作为参数名的字符(大写)
    */
    static getParaNameByType(languageType) {
        if (languageType == 0)
            return "CN";
        if (languageType == 2)
            return "EN";
        if (languageType == 15)
            return "EN-GB";
        if (languageType == 5)
            return "RU";
        if (languageType == 6)
            return "KO";
        if (languageType == 8)
            return "ES";
        return "CN";
    };
    /**
    * @description 获取语言作为路由字符(大写)
    */
    static getRouterUrlByType(languageType) {
        if (languageType == 0)
            return "CN";
        if (languageType == 2)
            return "EN";
        if (languageType == 15)
            return "EN-GB";
        if (languageType == 5)
            return "RU";
        if (languageType == 6)
            return "KO";
        if (languageType == 8)
            return "ES";
        return "CN";
    };
    /**
    * @description 获取语言名称(中文)
    */
    static getTypeNameCN(type) {
        let name = ""
        if (type == 0) {
            name = "简体中文"
        }
        if (type == 1) {
            name = "繁体中文"
        }
        if (type == 2) {
            name = "英语"
        }
        if (type == 15) {
            name = "英语(GB)"
        }
        if (type == 3) {
            name = "德文"
        }
        if (type == 4) {
            name = "日文"
        }
        if (type == 5) {
            name = "俄文"
        }
        if (type == 6) {
            name = "韩文"
        }
        if (type == 7) {
            name = "法文"
        }
        if (type == 8) {
            name = "西班牙文"
        }
        if (type == 9) {
            name = "意大利文"
        }
        if (type == 10) {
            name = "葡萄牙文"
        }
        if (type == 11) {
            name = "加泰罗尼亚文"
        }
        if (type == 12) {
            name = "波兰文"
        }
        if (type == 13) {
            name = "荷兰文"
        }
        if (type == 14) {
            name = "希腊文"
        }
        return name;
    };
    /**
    * @description 获取语言名称(英文)
    */
    static getTypeNameEN(type) {
        let name = ""
        if (type == 0) {
            name = "Simplified Chinese"
        }
        if (type == 1) {
            name = "Traditional Chinese"
        }
        if (type == 2) {
            name = "English"
        }
        if (type == 15) {
            name = "English(GB)"
        }
        if (type == 5) {
            name = "Russian"
        }
        if (type == 6) {
            name = "Korean"
        }
        return name;
    };
    //翻译
    static translateString(localizationType, cn) {
        if (localizationType == 0)
            return cn;
        if (localizationType == 6) {//ko
            if (cn == "流量")
                return "유량";
            else if (cn == "压力")
                return "압력받다";
            else if (cn == "扬程")
                return "양정";
            else if (cn == "效率")
                return "효율성";
            else if (cn == "功率")
                return "파워";
        }
        if (localizationType == 8) {//es
            if (cn == "流量")
                return "Caudal";
            else if (cn == "压力")
                return "Presión";
            else if (cn == "扬程")
                return "Cabezal";
            else if (cn == "效率")
                return "Eficiencia";
            else if (cn == "功率")
                return "Potencia";
        }
        if (localizationType == 5) {//ru
            if (cn == "流量")
                return "поток";
            else if (cn == "压力")
                return "давление";
            else if (cn == "扬程")
                return "напор";
            else if (cn == "效率")
                return "эффективность";
            else if (cn == "功率")
                return "мощность ";
        }
        if (cn == "流量")
            return "Flow";
        else if (cn == "压力")
            return "Press";
        else if (cn == "扬程")
            return "Head";
        else if (cn == "效率")
            return "Eta";
        else if (cn == "功率")
            return "Power";
        else if (cn == "汽蚀")
            return "NPSHr";
        else
            return cn;
    };
}

export default languageHelper