import db from '../db'
import router from '../../router/index'
const key = `token`
export default {
    namespaced: true,
    state: {
        /** 请求令牌 */
        accessToken: ''
    },
    actions: {
 
    },
    mutations: {
        /**
         * 从本地存储中加载令牌
         */
        loadfromlocalstorage(state) {
            const token = db.get(key)
            if (token) {
                state.accessToken = token.accessToken
            }
        },
 
        /**
         * 初始化令牌，如果不传则会从本地存储中获取
         */
        init(state, token) {
            //console.log(token)
            if (!token) {
                token = db.get(key)
            }
            if (token) {
                state.accessToken = token.accessToken
            }
            db.set(key, token)
        },
        /**
         * 清楚令牌
         */
        clear(state) {
            state.accessToken = ''

            db.remove(key)
        },
    },
}