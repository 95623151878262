import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../i18n/i18n';
Vue.use(Router)

const routes = [{
    path: '',
    alias: ['/EN/Index'],
    redirect: '/CN/Index'
},
{
    path: '/login/simpleLogin',
    name: 'login',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "login",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/simpleLogin.vue'),
},
//登录-邮箱登录
{
    path: '/CN/login/email',
    alias: ['/EN/login/email', '/EN-GB/login/email', '/KO/login/email', '/RU/login/email'],
    name: 'Email',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "login_email",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/email.vue'),
},
//登录-手机号登录
{
    path: '/CN/login/tel',
    alias: ['/EN/login/tel', '/EN-GB/login/tel', '/KO/login/tel', '/RU/login/tel'],
    name: 'Tel',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "login_tel",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/tel.vue'),
},
//微信登录绑定
{
    path: '/CN/userBind/wechart',
    alias: ['/EN/userBind/wechart', '/EN-GB/userBind/wechart', '/KO/userBind/wechart', '/RU/userBind/wechart'],
    name: 'wechartuserBind',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "userBind_wechart",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/bindwechart.vue'),
},
// 忘记密码
{
    path: '/CN/login/forgetPassword',
    alias: ['/EN/login/forgetPassword', '/EN-GB/login/forgetPassword', '/KO/login/forgetPassword', '/RU/userBind/wechart'],
    name: 'forgetPassword',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "login",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/forgetPassword.vue')
},
// 修改密码
{
    path: '/CN/login/changePassword',
    alias: ['/EN/login/changePassword', '/EN-GB/login/changePassword', '/KO/login/changePassword', '/RU/login/changePassword'],
    name: 'changePassword',
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "login",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/login/changePassword.vue'),
},
{
    path: '/CN/Select/ByPara/SelParas',
    name: 'SelParas',
    alias: ['/EN/Select/ByPara/SelParas'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara/SelParas.vue'),
},
{
    path: '/CN/Select/ByPara/SelSeries',
    name: 'ByParaSelSeries',
    alias: ['/EN/Select/ByPara/SelSeries'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara/SelSeries.vue'),
},
{
    path: '/CN/Select/ByPara/PumpList',
    name: 'ByParaPumpList',
    alias: ['/EN/Select/ByPara/PumpList'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara/PumpList.vue'),
},
{
    path: '/CN/EBook/SeriesList',
    name: 'SeriesList',
    alias: ['/EN/EBook/SeriesList'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/SeriesList.vue'),
},
//设计师作品列表
{
    path: '/CN/Desginer/SeriesList',
    name: 'desginerSeriesList',
    alias: ['/EN/Desginer/SeriesList'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/byDesginer/SeriesList.vue'),
},

{
    path: '/CN/EBook/SeriesListZM',
    name: 'SeriesListZM',
    alias: ['/EN/EBook/SeriesListZM'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/SeriesListZM.vue'),
},

{
    path: '/CN/Select/ByPara2/SelParas',
    name: 'SelParas2SelParas',
    alias: ['/EN/Select/ByPara2/SelParas'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara2",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara2/SelParas.vue'),
},
{
    path: '/CN/Select/ByPara2/PumpList',
    name: 'SelParas2PumpList',
    alias: ['/EN/Select/ByPara2/PumpList'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara2",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara2/PumpList.vue'),
},
{
    path: '/CN/Select/ByPara2/SelSeries',
    name: 'SelParas2SelSeries',
    alias: ['/EN/Select/ByPara2/SelSeries'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "byPara2",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/ByPara2/SelSeries.vue'),
},

{
    path: '/CN/Select/BySimuD/SelParas',
    name: 'BySimuDSelParas',
    alias: ['/EN/Select/BySimuD/SelParas'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "bySimuD",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/BySimuD/SelParas.vue'),
},
{
    path: '/CN/Select/BySimuD/SelSeries',
    name: 'BySimuDSelSeries',
    alias: ['/EN/Select/BySimuD/SelSeries'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "bySimuD",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/BySimuD/SelSeries.vue'),
},
{
    path: '/CN/Select/BySimuD/PumpList',
    name: 'BySimuDPumpList',
    alias: ['/EN/Select/BySimuD/PumpList'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "bySimuD",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Select/BySimuD/PumpList.vue'),
},
{
    path: '/CN/EBook/ApplicationBengXing',
    name: 'ApplicationBengXing',
    alias: ['/EN/EBook/ApplicationBengXing'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/ApplicationBengXing.vue'),
},
{
    path: '/CN/EBook/searchProduct',
    name: 'searchProduct',
    alias: ['/EN/EBook/searchProduct'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/searchProduct.vue'),
},
{
    path: '/CN/EBook/ApplicationHangYeMain',
    name: 'ApplicationHangYeMain',
    alias: ['/EN/EBook/ApplicationHangYeMain'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/ApplicationHangYeMain.vue'),
},
{
    path: '/CN/EBook/FactoryList',
    name: 'FactoryList',
    alias: ['/EN/EBook/FactoryList'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/FactoryList.vue'),
},
{
    path: '/CN/EBook/SeriesListBX',
    name: 'SeriesListBX',
    alias: ['/EN/EBook/SeriesListBX'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/SeriesListBX.vue'),
},
{
    path: '/CN/EBook/SeriesListHY3',
    name: 'SeriesListHY3',
    alias: ['/EN/EBook/SeriesListHY3'],
    meta: {
        title: '',
        cache: false,
        modulsName: "ebook",
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/SeriesListHY3.vue'),
},
{
    path: '/CN/EBook/SeriesDetailPump',
    name: 'SeriesDetailPump',
    alias: ['/EN/EBook/SeriesDetailPump'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "ebook",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/SeriesDetailPump.vue'),
},
// {
//     path: '/CN/index',
//     name: '中文首页',
//     alias: ['/EN/index'],
//     meta: {
//         title: '',
//         cache: false,
//         noFrame: false,
//         noPermissionValidate: true,
//     },
//     component: () =>
//         import ('../views/index.vue'),
// },
{
    path: '/CN/Detail/PumpDetail',
    name: 'PumpDetail',
    alias: ['/EN/Detail/PumpDetail'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        modulsName: "ebook",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Detail/components/detailMain.vue'),
},
{
    path: '/CN/PumpDetailIndex',
    name: 'PumpDetailIndex', //泵详细首页
    alias: ['/EN/PumpDetailIndex'],
    meta: {
        title: '',
        cache: false,
        noFrame: true,
        modulsName: "detail",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Detail/byParas/Index.vue'),
},
{
    path: '/CN/bySimuD/Index',
    name: 'SimuDIndex', //相似设计首页
    alias: ['/EN/bySimuD/Index'],
    meta: {
        title: 'SimDIndex',
        cache: false,
        noFrame: true,
        modulsName: "bySimuD",
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Detail/bySimuD/Index.vue'),
},

{
    path: '/CN/Index',
    name: 'home',
    alias: ['/EN/Index'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/Home.vue'),
},
{
    path: '/CN/ByDesigner',
    name: 'ByDesigner',
    alias: ['/EN/ByDesigner'],
    meta: {
        title: '',
        cache: false,
        noFrame: false,
        noPermissionValidate: true,
    },
    component: () =>
        import('../views/EBook/byDesginer/ByDesignerList.vue'),
},
]

const router = new Router({
    //mode: 'history',
    routes: routes
})


const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router