module.exports = {
    langType: "CN",
    message: {
        title: '运动品牌'
    },
    placeholder: {
        userName:"请输入您的用户名",
        password:"请输入您的密码",
        enter: '请输入您喜欢的品牌'
    },
    ebook:{
        serieslist:"电子样本",
    },
    select:{
        selparas:"参数设计",
        bySimuD:{
            selParas:'相似设计'
        }
    },
    lang:{
        lang:"语言",
        zh:"中文",
        en:"英文",
        russian:"俄文",
    },
    Applications:{
        Applications:"其他应用",
        danji:"单机版",
        android:"Android",
        ios:"IOS",
        wechat:"微信(Wechat)",
    },
    user:{
        userInfo:"账户信息",
        logout:"退出登录",
        login:"登录",
        
    },
    simpleLogin:{
        login:"登录",
        register:"注册",
        AccountLogin:"账户登录",
        CodeScanningLogin:"扫码登录",
        registerInfo:"如果您还没有加入我们，可以点击下方的注册成为我们的一员",
        registerInfoTitle:"新用户",
        loginInfo:"如果您已经是我们中的一员可以点击下方的登录，进入到我们的平台",
        loginInfoTitle:"我们中的一员",
        userType:"用户类型",
        loginName:"手机号",
        password:"密码",
        userType0:"内部人员",
        userType1:"外部人员",
        formValidate:{
            email0:"请输入邮箱",
            email1:"请输入正确的邮箱格式",
            TelPhone0:"请输入手机号码",
            TelPhone1:"请输入正确的手机号码格式",
            pass0:"请输入密码",
            pass1:"请输入密码长度为6~18位",
            pass2:"请输再次入密码",
            pass3:"两次密码输入不一样",
            loginName0:"请输入登录名",
            loginName1:"请输入登录名长度为6~18位",
            userName0:"请输入用户姓名",
            corpName0:"请输入公司名称",
        }
    },
    footer:{
        technical:"技术支持：上海义维流体科技有限公司",
        technicalAddres:"备案号为：沪ICP备14049296号-2",
        copyright:" ",
        copyrightAddres:" ",
    }

}