module.exports = {
    ebookPage: {
        all: { ID: 787, TR: "全部" },
        driveType: { ID: 2366, TR: "驱动类型" },
        motor: { ID: 846, TR: "电机" },
        diesel: { ID: 1085, TR: "柴油机" },
        noProfile: { ID: 2469, TR: "暂无简介" },
        serieslist: { ID: 1, TR: "电子样本" },
        motorFrequency: { ID: 72, TR: "电机频率" },
        motorStandard: { ID: "", TR: "Unit Standard" },
        displayMode: { ID: 670, TR: "显示方式" },
        list: { ID: 671, TR: "列表" },
        grid: { ID: 672, TR: "网格" },
        keyWord: { ID: 667, TR: "关键词" },
        keyWordRule: { ID: 171, TR: "请输入关键字" },
        view: { ID: 250, TR: "查询" },
        queryResults: { ID: 2470, TR: "查询结果" },

        operation: { ID: 1208, TR: "操作" },
        download: { ID: 449, TR: "下载" },
        type: { ID: 245, TR: "类型" },
        name: { ID: 447, TR: "名称" },
        updateTime: { ID: 772, TR: "更新时间" },
        version: { ID: 773, TR: "文件版本" },
        lang: { ID: 450, TR: "语言" },
        size: { ID: 1474, TR: "文件尺寸" },
        desc: { ID: 448, TR: "文件描述" },
        noData: { ID: 2305, TR: "暂无数据" },

        emptyFlowAndHead: { ID: 2528, TR: "流量和扬程不能为空" }, //!!!!
        details: { ID: 999, TR: "详情" },
        noData: { ID: "", TR: "暂无数据" },


        pumpList: { ID: 122, TR: "列表" },
        spectrumList: { ID: 6, TR: "型谱" },
        modelDrawing: { ID: 182, TR: "实物图" },
        seriesDescription: { ID: 144, TR: "系列说明" },
        seriesDocuments: { ID: 693, TR: "系列文档" },
        video: { ID: 694, TR: "详情视频" },
        model_3D: { ID: 131, TR: "三维模型" },
        export: { ID: 276, TR: "导出" },
        no: { ID: 695, TR: "序号" },
        flow: { ID: 48, TR: "流量" },
        head: { ID: 49, TR: "扬程" },
        catalog: { ID: 245, TR: "按类型" },
        selectIndustry: { ID: 2471, TR: "选择行业" },
        allSeries: { ID: 1984, TR: "所有系列" },

        productStructure: { ID: "", TR: "产品结构" },

    },
    selectPage: {
        selparas: { ID: 5, TR: "参数选型" },
        selResult: { ID: 2418, TR: "选型结果" },
        index: { ID: 1437, TR: "首页" },
        selRow: { ID: 736, TR: "列选择" },
        export: { ID: 276, TR: "导出" },
        print: { ID: 192, TR: "打印" },
        previous: { ID: 367, TR: "上一步" },
        physical: { ID: 184, TR: "实物图" },
        series: { ID: 253, TR: "系列" },
        model: { ID: 100, TR: "型号" },
        diagram: { ID: 781, TR: "曲线图" },
        flow: { ID: 48, TR: "流量" },
        head: { ID: 49, TR: "扬程" },
        shaftPower: { ID: 109, TR: "轴功率" },
        efficiency: { ID: 108, TR: "效率" },
        npsh: { ID: 110, TR: "汽蚀" },
        speed: { ID: 114, TR: "转速" },
        impellerDiameter: { ID: 223, TR: "叶轮直径" },
        details: { ID: 999, TR: "详情" },

        certifiedTraffic: { ID: "",  TR: "认证流量" },
        certifiedHead:{ ID: "",  TR: "认证扬程" },
        certifiedSpeed:{ ID: "", TR: "认证转速" },
        pointFlow_150:{ ID: "", TR: "150%点流量" },
        pointLift_150:{ ID: "", TR: "150%点扬程" },
        pointPower_150:{ ID: "",  TR: "150%点功率" },
        maxShaftPower:{ ID: "",  TR: "最大轴功率" },
        deadCenterLift:{ ID: "",  TR: "关死点扬程" },
        pumpStages:{ ID: "",  TR: "泵级数" },
        note:{ ID: "",TR: "备注" },


        gotoIndexPage: { ID: 2472, TR: "返回首页" },
        quickSelection: { ID: 2419, TR: "快速选型" },
        advancedSelection: { ID: 2420, TR: "高级选型" },
        workingConditions: { ID: 2421, TR: "工况条件" },
        productSeries: { ID: 2422, TR: "产品系列" },
        driveType: { ID: 2423, TR: "驱动类型" },
        setFrequency: { ID: 2424, TR: "设置频率" },
        moreDesignPoints: { ID: 1429, TR: "更多设计点", },
        hide: { ID: 2425, TR: "隐藏" },
        secondDpFlow: { ID: 2426, TR: "第二设计点流量" },
        secondDpHead: { ID: 2427, TR: "第二设计点扬程" },
        thirdDpFlow: { ID: 2428, TR: "第三设计点流量" },
        thirdDpHead: { ID: 2429, TR: "第三设计点扬程" },
        firePump: { ID: 2389, TR: "消防泵" },
        motorPoleNum: { ID: 2430, TR: "电机级数" },
        moreSet: { ID: 2431, TR: "更多设置" },
        put: { ID: 2432, TR: "收起" },
        motorPowerStd: { ID: 2433, TR: "电气标准" },
        mediaName: { ID: 2434, TR: "介质名称" },
        cleanWater: { ID: 465, TR: "清水" },
        maxTemperature: { ID: 2435, TR: "最高温度" },
        customMediaProp: { ID: 2436, TR: "自定义介质属性" },
        maxDensity: { ID: 2437, TR: "最大密度" },
        mediumViscosity: { ID: 2438, TR: "介质粘度" },
        nextStep: { ID: 368, TR: "下一步" },
        selectAll: { ID: 97, TR: "全选" },
        reselect: { ID: 366, TR: "取消全选" },
        modelList: { ID: 443, TR: "型号列表" },
        byType: { ID: 2439, TR: "按类型" },
        byIndustry: { ID: 2440, TR: "按行业" },
        byMedia: { ID: 2473, TR: "按介质" },
        applicationArea: { ID: 2441, TR: "应用领域" },
        flowRule: { ID: 2143, TR: "请输入流量" },
        headRule: { ID: 1164, TR: "请输入扬程" },
        type: { ID: 247, TR: "类型" },

        leastOneType:{ ID: "", TR: "至少选择一种类型" },
        seriesRule: { ID: 2444, TR: "至少选择一个系列" },
        hangyeRule: { ID: 2474, TR: "至少选择一个行业" },
        jiezhiRule: { ID: 2475, TR: "至少选择一种介质" },
        dieselEngineSpeedRule: { ID: 2443, TR: "请输入柴油机转速" },
        dieselEngineSpeed: { ID: 2442, TR: "柴油机转速" },
        pleaseSelect: { ID: 2042, TR: "请选择" },

        custom: { ID: "", TR: "自定义" },
        unlimited: { ID: "", TR: "不限" },

        toleranceStand: { ID: "", TR: "验收容差标准" },
        toleranceGrade: { ID: "", TR: "验收容差等级" },
        MinFlowPercentage: { ID: "", TR: "最小流量百分比" },
        MaxFlowPercentage: { ID: "", TR: "最大流量百分比" },
        MinHeadPercentage: { ID: "", TR: "最小扬程百分比" },
        MaxHeadPercentage: { ID: "", TR: "最大扬程百分比" },

        findErr1:{ID:"",TR:"未查找到相关型号,请修改相关选型参数"},
        findErr2:{ID:"",TR:"未查找到相关型号,将在三秒后返回上一页"}
    },
    //详情页面
    detailPage: {
        userUnit: { ID: "", TR: "用户单位" },
        standardUnit: { ID: "", TR: "标准单位" },
        report: { ID: 702, TR: "报告" },
        share: { ID: 703, TR: "分享" },
        costAnalysis: { ID: 571, TR: "成本分析" },
        medium: { ID: 1434, TR: "介质" },
        model_3D: { ID: 131, TR: "三维模型" },

        close: { ID: 460, TR: "关闭" },
        setUp: { ID: 470, TR: "设定" },
        flowUnits: { ID: 2134, TR: "流量单位" },
        headUnit: { ID: 2135, TR: "扬程单位" },
        powerUnit: { ID: 2457, TR: "功率单位" },
        npshUnit: { ID: "", TR: "汽蚀单位" },
        d2Unit:{ ID: "", TR: "叶轮外径单位" },

        followSystem: { ID: "", TR: "跟随系统" },

        exitFull: { ID: 2458, CN: "退出全屏", TR: "退出全屏" },
        partNo: { ID: 158, TR: "产品编号" },
        materialGroup: { ID: 1712, TR: "材料组" },
        material: { ID: 1215, TR: "材料" },

        scanQRShare: { ID: 2456, TR: "扫描二维码分享" },
        selectionReport: { ID: 236, TR: "选型报告" },
        view: { ID: 250, TR: "查询" },
        lang: { ID: 451, TR: "语言" },
        fileFormat: { ID: 1532, TR: "文件格式" },
        buildFile: { ID: 2445, TR: "生成文件" },
        customerInfo: { ID: 795, TR: "Customer Info" },
        orderInformation: { ID: 884, TR: "订单信息" },
        setReportContent: { ID: 889, TR: "设置报告内容" },
        projectInformation: { ID: 89, TR: "项目信息" },
        productName: { ID: 881, TR: "产品名称" },
        projectCode: { ID: 1027, TR: "项目编号" },
        projectName: { ID: 800, TR: "项目名称" },
        itemNO: { ID: 801, TR: "工位编号" },
        customerInfo: { ID: 795, TR: "客户信息" },
        customerName: { ID: 883, TR: "客户名称" },
        customerAddress: { ID: 968, TR: "客户地址" },
        contactPerson: { ID: 766, TR: "联系人" },
        contactInformation: { ID: 35, TR: "联系方式" },
        email: { ID: 1801, TR: "邮箱" },
        website: { ID: 1082, TR: "网址" },
        country: { ID: 1083, TR: "国家" },
        orderInformation: { ID: 1798, TR: "订单信息" },
        pumpPeformance: { ID: 1796, TR: "订单号" },
        price: { ID: 886, TR: "产品单价" },
        purchaseQuantity: { ID: 888, TR: "购买数量" },
        orderNote: { ID: 885, TR: "订单备注" },
        pageReportSet: { ID: 2454, TR: "页面报告设置" },
        isBWDispplay: { ID: 820, TR: "是否黑白显示曲线" },
        dataAndCurve: { ID: 817, TR: "数据表和曲线" },
        curveGraph: { ID: 781, TR: "曲线图" },
        sizePicture: { ID: 705, TR: "尺寸图" },
        assemblyDrawing: { ID: 133, TR: "安装图" },
        modelDrawing: { ID: 182, TR: "实物图" },
        explosionDiagram: { ID: "", TR: "爆炸图" },
        modelDrawinUnit: { ID: 2455, TR: "实物图(机组)" },
        structureDrawing: { ID: 184, TR: "结构图" },

        offerText: { ID: 814, TR: "报价文本" },
        companyName: { ID: 1011, TR: "公司抬头" },
        companyWebsite: { ID: 1799, TR: "公司网址" },
        performanceCurve: { ID: 217, TR: "性能曲线" },
        assemblyDrawingPart: { ID: 2446, TR: "安装图(单泵)" },
        assemblyDrawingSys: { ID: 2447, TR: "安装图(机组)" },
        parameter: { ID: 111, TR: "参数" },
        data: { ID: 2056, TR: "数据" },
        unit: { ID: 527, TR: "单位" },
        operatingParameters: { ID: 2157, TR: "运行参数" },
        parameterQuery: { ID: 2448, TR: "参数查询" },
        installation: { ID: 2201, TR: "安装信息" },
        motor: { ID: 2202, TR: "电机信息" },
        documents: { ID: 693, TR: "文档" },
        properties: { ID: 229, TR: "属性" },
        material: { ID: 2170, TR: "材质" },
        flow: { ID: 48, TR: "流量" },
        head: { ID: 49, TR: "扬程" },
        shaftPower: { ID: 109, TR: "轴功率" },
        efficiency: { ID: 108, TR: "效率" },

        press: { ID: "",  TR: "压力" },
        power: { ID: "", TR: "功率" },
        impellerDia: { ID: "", TR: "叶轮外径" },
        motorPower: { ID: "", TR: "电机功率" },

        npsh: { ID: 110, TR: "汽蚀" },
        speed: { ID: 114, TR: "转速" },
        coefficient: { ID: 1143, TR: "系数" },
        parameterSelection: { ID: 5, TR: "参数选型" },
        allowableRegionMax: { ID: 2449, TR: "允许区域(大)" },
        allowableRegionMin: { ID: 2450, TR: "允许区域(小)" },
        optimalAreaMax: { ID: 2451, TR: "最优区域(大)" },
        optimalAreaMin: { ID: 2452, TR: "最优区域(小)" },
        closeDeadCenter: { ID: 2453, TR: "关死点" },
        bestAdvantage: { ID: 2459, TR: "最优点" },
        workingPoint: { ID: 202, TR: "工作点" },
        flowPoint150: { ID: 2460, TR: "流量150%点" },
        powerPointMax: { ID: 2461, TR: "最大功率点" },
        workingPoint: { ID: "", TR: "工作点" },
        video: { ID: 694, TR: "详情视频" },
        variableSpeedCurve: { ID: 2476, TR: "变速曲线" },

        displaySettings: { ID: 1614, TR: "显示设置" },
        curveExport: { ID: 2477, CN: "曲线导出", TR: "曲线导出" },

        chartColor: { ID: 2478, TR: "图表颜色"},
        monochrome: { ID: 138, TR: "单色" },
        multicolour: { ID: 1890, TR: "彩色" },
        spectrumDisplay: { ID: 2479, TR: "型谱显示" },
        display: { ID: 2114, TR: "显示" },
        noDisplay: { ID: 157, TR: "不显示" },
        onlyShowworkCurve: { ID: 1990, TR: "仅显示工作曲线" },

        

        speedList: { ID: 2480, TR: "速度列表" },
        addSpeed: { ID: 2481, TR: "添加速度" },
        pleaseEnterSpeed: { ID: 1169, TR: "请输入转速" },
        tip: { ID: 285, TR: "提示" },
        define: { ID: 124, TR: "确定" },
        cancel: { ID: 125, TR: "取消" },
        download: { ID: 449, TR: "下载" },
        downloadTips: { ID: 2482, TR: "下载桌面程序,导出性能曲线" }
    },
    //报表分析
    LCC: {
        Analysis: { ID: 569, TR: "分析" },
        accumulationCurve: { ID: 943, TR: "年度累计曲线" },
        statisticalChart: { ID: 944, TR: "单年统计图" },
        workingPointFlow: { ID: 555, TR: "工作点流量" },
        default: { ID: 937, TR: "默认" },

        heating: { ID: 938, TR: "供暖" },
        pressurize: { ID: 939, TR: "增压" },
        custom: { ID: 940, TR: "自定义" },
        status: { ID: 867, TR: "状态" },
        flowRatio: { ID: 948, TR: "流量比例" },
        timeScale: { ID: 949, TR: "时间比例" },
        fullLoad: { ID: 950, TR: "全负荷" },
        partialLoad: { ID: 952, TR: "部分负荷" },
        nightCoolingOperation: { ID: 954, TR: "夜间降温运行" },
        lowLoad: { ID: 953, TR: "低负荷" },
        load: { ID: 2483, TR: "负荷" },

        energyCosts: { ID: 561, TR: "能耗成本" },
        workingDays: { ID: 556, TR: "每年大致工作天数" },
        workingHours: { ID: 557, TR: "每年大致工作小时" },
        powerFees: { ID: 567, TR: "电费" },
        per: { ID: 565, CN: "每度", TR: "per" },
        risiPowerPrice: { ID: 2484, TR: "电费上涨" },
        eachYear: { ID: 566, TR: "每年" },
        CalcCycle: { ID: 558, TR: "计算周期" },
        year: { ID: 559, TR: "年" },
        interestRate: { ID: 560, TR: "利率" },
        droop: { ID: 568, TR: "效率下降" },
        co2Ratio: { ID: 936, TR: "CO2系数" },
        conversionFactor: { ID: 2529, TR: "煤炭折算系数" },

        maintenanceCost: { ID: 536, TR: "维护维修成本" },
        thousand: { ID: 2485, TR: "千元" },
        maintenance: { ID: 2486, TR: "维护和维修" },
        runningCost: { ID: 542, TR: "运行费用" },
        downtimeAndLoss: { ID: 540, TR: "生产停工和损耗" },

        environmentalFee: { ID: 541, TR: "环境保护费" },
        othersFee: { ID: 792, TR: "其他年费用" },
        initialCost: { ID: 549, TR: "一次性初始成本" },
        orderCost: { ID: 2487, TR: "设备采购费用" },
        installDebugCharge: { ID: 551, TR: "安装和调试" },
        tonAndkW: { ID: 2488, TR: "吨/万千瓦" },

        mapOfCosts: { ID: 941, TR: "费用图" },
        costItem: { ID: 945, TR: "费用项" },
        cost: { ID: 946, TR: "费用" },
        percentage: { ID: 947, TR: "百分比" },
        initialCost: { ID: 662, TR: "初期成本" },
        maintenanceAndRepair: { ID: 543, TR: "维修和护理" },
        co2: { ID: 2489, TR: "CO2" },
        coalConsumption: { ID: 2490, TR: "煤耗量" },
        totalAnnualCost: { ID: 958, TR: "年总费用" },
        co2TotalEmissions: { ID: 2491, TR: "co2总排放量" },
        tenThousand: { ID: 956, TR: "万" },
        tenThousandTon: { ID: 2492, TR: "万吨" },

    },

    //首页
    indexPage: {
        corpIntroduction: { ID: 30, TR: "公司介绍" },
        basicInformation: { ID: "", TR: "基础信息" }, 

        organizationStructure:{ ID: "", TR: "组织结构" },
        corporateCulture:{ ID: "", TR: "企业文化" },
        introductionToPumpAnimation:{ ID: "", TR: "泵型动画介绍" },
        companyPortfolio:{ ID: "", TR: "公式组合" },

        enterpriseHonors: { ID: 33, TR: "企业荣誉" },
        enterpriseStyle: { ID: 2386, TR: "企业风貌" },
        salesNetwork: { ID: 31, TR: "销售网络" },
        performanceSelection: { ID: 2387, TR: "水力性能选型" },
        productSearch: { ID: 786, TR: "产品搜索" },
        contactinformation: { ID: 35, TR: "联系方式" },
        ebook: { ID: 1, TR: "电子样本" },
        pdfFile: { ID: 1755, TR: "纸质样本" },
        application: { ID: 2493, TR: '行业应用' },
        intelligentSelection: { ID: 69, TR: "智能选型" },
        firePump: { ID: 2389, TR: "消防泵" },
        flow: { ID: 48, TR: "流量" },
        head: { ID: 49, TR: "扬程" },
        type: { ID: 247, TR: "类型" },
        more: { ID: 741, TR: "更多" },
        nextStep: { ID: 369, TR: "下一步" },
        zmSearch: { ID: 2494, TR: "按字母" },
        alphaList: { ID: "", TR: "字母列表" },
        byType: { ID: 2439, TR: "按类型" },
        byIndustry: { ID: 2383, TR: "按行业" },
        byProductLine:{ ID: "", TR: "按产品线" },
        
        product:{ ID: "", TR: "产品" },
        material:{ ID: "", TR: "资料" },

        model_3D: { ID: 131, TR: "bim模型" },
        about_us: { ID: 2495, TR: "关于我们" },
        product_catalog: { ID: 2496, TR: "产品目录" },
        product_selection: { ID: 2497, TR: "产品选型" },
        letter_search: { ID: 2498, TR: "字母搜索" },
        application_HY:{ ID: "", TR: "行业应用" },
        application_area: { ID: 2441, TR: "应用领域" },
        sample_Model: { ID: 2499, TR: "样本模型" },
        pleaseSelect: { ID: 2042, TR: "请选择" },

        add: { ID: 722, TR: "公司地址:" },
        companyAddress: { ID: "", TR: "浙江省湖州市德清县雷甸镇旭日路8号" },
        tel: { ID: 2500, TR: "联系电话:" },
        email: { ID: 2501, TR: "企业邮箱:" },
        hotline: { ID: 2502, TR: "全国同一服务热线:" },

        helpManual: { ID: "", TR: "帮助手册" },
        changePassword: { ID: "", CN: "修改密码", TR: "修改密码" },

        viewNow: { ID: "", TR: "立即查看" },
        quick: { ID: "", TR: "快速" },
        sampleBook: { ID: "", TR: "产品样本" },
        simpleAndDirect: { ID: "", TR: "清晰简捷" },
        clearClassification: { ID: "", TR: "分类明确" },
        quickProductSelection: { ID: "", TR: "产品快速选取" },
        productSamplePreview: { ID: "", TR: "产品样本预览" },
        productNameAtoZ: { ID: "", TR: "产品名称A-Z" },
        findApplications: { ID: "", TR: "发现适合您需求的应用" },

        feedBack: { ID: "", TR: "问题反馈" },
        feedbackContent: { ID: "", TR: "反馈内容" },
        feedbackRule: { ID: "", TR: "联系人或联系方式不能为空!!" },
        
        problemType:{ ID: "", TR: "问题类型" },
        rorExample:{ ID: "", TR: "例如:软件问题、曲线错误" }

    },
    simpleLoginPage: {
        login: { ID: 681, TR: "登录" },
        register: { ID: 381, TR: "注册" },
        enterVerificCode: { ID: 2503, TR: "请输入验证码" },
        verificCodeError: { ID: 2504, TR: "验证码错误" },
        loginPrompt: { ID: 2505, TR: "登录提示" },
        passwordError: { ID: 400, TR: "密码错误" },
        loginSuccessful: { ID: 2506, TR: "登录成功,正在跳转~" },
        AccountLogin: { ID: 2507, TR: "账户登录" },
        CodeScanningLogin: { ID: 2390, TR: "扫码登录" },
        registerInfo: { ID: 2391, TR: "如果您还没有加入我们，可以点击下方的注册成为我们的一员" },
        registerInfoTitle: { ID: 2392, TR: "新用户" },
        loginInfo: { ID: 2393, TR: "如果您已经是我们中的一员可以点击下方的登录，进入到我们的平台" },
        loginInfoTitle: { ID: 2394, TR: "我们中的一员" },
        userType: { ID: 2395, TR: "用户类型" },
        loginName: { ID: 2396, TR: "账户名" },
        password: { ID: 379, TR: "密码" },
        userType0: { ID: 710, TR: "内部人员" },
        userType1: { ID: 2397, TR: "外部人员" },
        phoneNumber: { ID: 379, TR: "手机号" },
        email: { ID: 1801, TR: "邮箱" },

        forgetPassword:{ ID: "",  TR: "忘记密码" },
        sendSuccess:{ ID: "",  TR: "发送成功" },
        verifyAccount:{ ID: "",  TR: "账号验证" },
        fillInInformation:{ ID: "",  TR: "填写信息" },
        registerSuccess:{ ID: "", TR: "注册成功" },
        registerTips:{ ID: "",  TR: "请填写您注册时的手机号" },

        verifyCode:{ ID: "", CN: "验证码", TR: "verifyCode" },
        send:{ ID: "", CN: "发送", TR: "send" },
        userAccount:{ ID: "", CN: "用户账号", TR: "User account" },

        oldPassword: { ID: "", TR: "旧密码" },
        oldPsdTips: { ID: "", TR: "请输入旧密码" },
        newPassword: { ID: "", TR: "新密码" },
        newPsdTips: { ID: "", TR: "请输入新密码" },
        oldPwdRule: { ID: "", TR: "旧密码不能为空" },
        newPwdRule: { ID: "", TR: "新密码不能为空" },
        oldAndNewPwdRule: { ID: "", TR: "新旧密码不能一致" },

        enterPwdAgain:{ ID: "", TR: "请再次输入密码" },
        enterTwoPwdDisaccord:{ ID: "",TR:"两次输入密码不一致" },

        changeSuccess: { ID: "", TR: "修改成功" },
        pwdRule: { ID: "", TR: "密码不一致" },
        accountSettings:{ID: "", TR: "账号设置"},

        confirmPassword: { ID: "", TR: "确定密码" },
        realName: { ID: "", TR: "用户名" },
        corpName: { ID: "", TR: "公司名称" },
        corpAddress: { ID: "", TR: "公司地址" },

        formValidate: {
            email0: { ID: 780, TR: "请输入邮箱" },
            email1: { ID: 2398, TR: "请输入正确的邮箱格式" },
            TelPhone0: { ID: 2399, TR: "请输入手机号码" },
            TelPhone1: { ID: 2400, TR: "请输入正确的手机号码格式" },
            pass0: { ID: 2401, TR: "请输入密码" },
            pass1: { ID: 2402, TR: "请输入密码长度为6~18位" },
            pass2: { ID: 2403, TR: "请再次输入密码" },
            pass3: { ID: 2404, TR: "两次密码输入不一样" },
            loginName0: { ID: 2405, TR: "请输入登录名" },
            loginName1: { ID: 2406, TR: "请输入登录名长度为6~18位" },
            userName0: { ID: 407, TR: "请输入用户姓名" },
            corpName0: { ID: 2408, TR: "请输入公司名称" },
            corpAddress0: { ID: "", TR: "请输入公司地址" },
        }
    },
    // 头部header栏
    header: {
        serieslist: { ID: 1, TR: "电子样本" },
        selparas: { ID: 5, TR: "参数选型" },
        lang: { ID: 451, TR: "语言" },
        cn: { ID: 721, TR: "中文" },
        en: { ID: 297, TR: "英文" },
        ko: { ID: 298, TR: "韩文" },
        russian: { ID: 303, TR: "俄文" },
        es: { ID: 2462, TR: "西班牙文" },
        Applications: { ID: 2409, TR: "其他应用" },
        danji: { ID: 2410, TR: "单机版" },
        android: { ID: 2411, TR: "安卓" },
        ios: { ID: 2412, TR: "苹果" },
        wechat: { ID: 2413, TR: "微信" },
        userInfo: { ID: 23, TR: "账户信息" },
        logout: { ID: 714, TR: "退出登录" },
        compareList: { ID: 856, TR: "比较列表" },
        collectList: { ID: 2508, TR: "收藏列表" },
        collect: { ID: 1702, TR: "收藏" },
        addCollect: { ID: 2509, TR: "加入收藏" },
        deleteCollect: { ID: 858, TR: "清除收藏" },
        compare: { ID: 701, TR: "比较" },
        addCompare: { ID: 855, TR: "加入比较" },
        deleteCompare: { ID: 857, TR: "清除比较" },

        comparisonAdded: { ID: 2510, TR: "已经加入比较列表" },
        addComparSueecss: { ID: 2511, TR: "加入比较列表成功" },
        clearComparList: { ID: 2512, TR: "确认清除比较列表" },
        clearComparSuccess: { ID: 2513, TR: "清除比较列表成功" },
        confirmDelCompar: { ID: 2514, TR: "确认删除此型号的比较" },
        deleteSuccess: { ID: 2515, TR: "删除成功" },
        confirmDelCollect: { ID: 2516, TR: "确认删除此收藏" },
        confirmClearCollect: { ID: 2517, TR: "确认清除收藏" },
        clearCollectSueecss: { ID: 2518, TR: "清除成功" },
        collecExists: { ID: 2519, TR: "收藏已存在" },
        collectSuccess: { ID: 2520, TR: "收藏成功" },

        login: { ID: 681, TR: "登录" },
        information: { ID: 2521, TR: "个人信息" },
        tipInfo: { ID: 2522, TR: "您确定要退出系统吗" },
        tip: { ID: 285, TR: "提示" },
        define: { ID: 124, TR: "确定" },
        cancel: { ID: 125, TR: "取消" },
        collectTip: { ID: 2523, TR: "收藏列表为空" },
        compareTip: { ID: 2524, TR: "比较列表至少需要两项" },


        accountInfo: { ID: 2525, TR: "账户信息" },
        baseInfo: { ID: 424, TR: "基本信息" },
        realName: { ID: 638, TR: "真实姓名" },
        account: { ID: 2526, TR: "账号" },
        telPhoneNumber: { ID: 379, TR: "手机号" },
        email: { ID: 1801, TR: "邮箱" },
        corpName: { ID: 102, TR: "公司名称" },
        corpAddress: { ID: 722, TR: "公司地址" },
        userSetting: { ID: "", TR: "用户设置" }
    },
    // 底部footer 栏
    footer: {
        version:{ ID: "", TR: "版本" },
        technical: { ID: 709, TR: "技术支持：上海义维流体科技有限公司" },
        technicalAddres: { ID: 2415, TR: "备案号为：沪ICP备14049296号-2" },
        copyright: { ID: 2416, TR: "版权所有：上海义维流体科技有限公司" },
        copyrightAddres: { ID: 2417, TR: "地址：上海市闵行区江月路999号1幢615" },
        tips: { ID: 2527, TR: "数据仅供参考：如有修改恕不通知 版权所有，盗版必究" }
    },

}