import languageHelper from "@/utils/languageHelper";

var languageMixin = {
    data() {
        m_currentLanguage: 0
    },
    beforeMount() {
        //初始化语言
        this.initialLanguage();
    },
    methods: {
        //初始化语言
        initialLanguage() {
            var currentLanguage = this.getLanguageTypeFromRout();
            this.m_currentLanguage = currentLanguage;
            if (currentLanguage == 0) {
                localStorage.setItem('lang', "cn")
                this.$i18n.locale = "cn";
            }
            else if (currentLanguage == 2) {
                localStorage.setItem('lang', "en")
                this.$i18n.locale = "en";
            }
            else if (currentLanguage == 5) {
                localStorage.setItem('lang', "ru")
                this.$i18n.locale = "ru";
            }
            else if (currentLanguage == 15) {
                localStorage.setItem('lang', "en_gb")
                this.$i18n.locale = "en_gb";
            }
            else if (currentLanguage == 6) {
                localStorage.setItem('lang', "ko")
                this.$i18n.locale = "ko";
            }
            else if (currentLanguage == 8) {
                localStorage.setItem('lang', "es")
                this.$i18n.locale = "es";
            }
            else {
                localStorage.setItem('lang', "cn")
                this.$i18n.locale = "cn";
            }
        },
        //从路由中获取语言类型
        getLanguageTypeFromRout() {
            return languageHelper.getTypeFromRout(window.location.href);
        },
        gotoPage(url, query, replace) {
            if (replace == null)
                replace = true;
            var url_lang = this.buildCurrentLanguageUrl(url);
            this.$router.push({
                path: url_lang,
                query: query,
                replace: replace
            });
        },
        buildCurrentLanguageUrl(url) {
            if (url.startsWith("/")) {
                return `/${this.getCurrentLanguageUrl()}` + url;
            }
            else {
                return `/${this.getCurrentLanguageUrl()}/` + url;
            }
        },
        gotoIndexPage() {
            this.$router.push({
                path: `/${this.getCurrentLanguageUrl()}/Index`,
            });
        },
        //获取语言名称(大写)
        getLanguageParaName() {
            return languageHelper.getParaNameByType(this.m_currentLanguage);
        },
        //获取语言类型
        getCurrentLanguageType() {
            return this.m_currentLanguage;
        },
        //获取语言路由字符
        getCurrentLanguageUrl() {
            return languageHelper.getRouterUrlByType(this.m_currentLanguage);
        },
        //获取当前的公司名称(简称)
        getCorpShortName() {
            if (this.m_currentLanguage == 0)
                return window.globalConfig.CorpInfo.CorpShortName.CN;
            if (this.m_currentLanguage == 2)
                return window.globalConfig.CorpInfo.CorpShortName.EN;
            if (this.m_currentLanguage == 15)
                return window.globalConfig.CorpInfo.CorpShortName.EN;
            if (this.m_currentLanguage == 5)
                return window.globalConfig.CorpInfo.CorpShortName.EN;
            if (this.m_currentLanguage == 6)
                return window.globalConfig.CorpInfo.CorpShortName.KO;
            else
                return window.globalConfig.CorpInfo.CorpShortName.CN;
        },
        //获取当前的公司名称(全称)
        getCorpFullName() {
            if (this.m_currentLanguage == 0)
                return window.globalConfig.CorpInfo.CorpFullName.CN;
            if (this.m_currentLanguage == 2)
                return window.globalConfig.CorpInfo.CorpFullName.EN;
            if (this.m_currentLanguage == 15)
                return window.globalConfig.CorpInfo.CorpFullName.EN;
            if (this.m_currentLanguage == 5)
                return window.globalConfig.CorpInfo.CorpFullName.EN;
            if (this.m_currentLanguage == 6)
                return window.globalConfig.CorpInfo.CorpFullName.KO;
            else
                return window.globalConfig.CorpInfo.CorpFullName.CN;
        },
    }
}

export default languageMixin 